import { useState } from 'react';
// @iconify
import { Icon } from '@iconify/react';
// @mui
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import OddButton from 'components/Sports/OddButton';

const EventDetail = ({ isFull }: { isFull: boolean }) => {
    const [value, setValue] = useState(0);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    const renderScorBoard = (
        <Box>
            <Box sx={{ mb: 0.2 }}>
                <Box
                    sx={{
                        p: 1,
                        position: 'relative',
                        borderRadius: 1,
                        background: 'url(https://www.baladbet.com/assets/soccer-4729ed8e.png) no-repeat 50% 50%/cover',
                        '&::before': {
                            content: `""`,
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            bottom: 0,
                            right: 0,
                            pointerEvents: 'none',
                            background: '#0006'
                        }
                    }}
                >
                    <Stack direction="row" alignItems="flex-start" sx={{ position: 'relative', width: 1, mb: 1 }}>
                        <Stack direction="row" alignItems="center" sx={{ flex: 'auto', flexWrap: 'wrap' }}>
                            <Box
                                component="i"
                                className="bc-i-Soccer"
                                sx={{ fontSize: 13, mb: 0.4, color: '#ffffffb3' }}
                            />
                            <Typography
                                component="span"
                                sx={{
                                    ml: 0.8,
                                    flex: 'auto',
                                    fontSize: 12,
                                    lineHeight: '14px',
                                    textTransform: 'capitalize',
                                    color: '#ccc',
                                    mb: 0.4
                                }}
                            >
                                Premier League
                            </Typography>
                        </Stack>
                        {/* <Stack
                                        alignItems="center"
                                        justifyContent="center"
                                        sx={{
                                            ml: 1,
                                            fontSize: 12,
                                            bgcolor: '#109121',
                                            borderRadius: 1,
                                            height: 30,
                                            px: 0.5,
                                            flexShrink: 0,
                                            fontWeight: 500,
                                            boxShadow: '0 4px 4px #0000000d'
                                        }}
                                    >
                                        Half Time
                                    </Stack> */}
                        <Stack
                            alignItems="center"
                            justifyContent="center"
                            sx={{
                                ml: 1,
                                fontSize: 12,
                                bgcolor: '#10912180',
                                borderStartStartRadius: 4,
                                borderEndStartRadius: 4,
                                height: 30,
                                px: 0.5,
                                flexShrink: 0,
                                fontWeight: 500,
                                boxShadow: '0 4px 4px #0000000d'
                            }}
                        >
                            2nd Half
                        </Stack>
                        <Stack
                            alignItems="center"
                            justifyContent="center"
                            sx={{
                                ml: 0.1,
                                fontSize: 12,
                                bgcolor: '#109121',
                                borderStartEndRadius: 4,
                                borderEndEndRadius: 4,
                                height: 30,
                                px: 0.5,
                                flexShrink: 0,
                                fontWeight: 500,
                                boxShadow: '0 4px 4px #0000000d'
                            }}
                        >
                            0'
                        </Stack>
                    </Stack>

                    <Stack
                        direction="row"
                        sx={{
                            position: 'relative',
                            width: 1,
                            overflow: 'hidden',
                            py: 1,
                            background: '#0000004d',
                            boxShadow: '-11px 0 #0000004d, 11px 0 #0000004d'
                        }}
                    >
                        <Stack
                            sx={{
                                flex: 'auto',
                                overflow: 'hidden',
                                justifyContent: 'space-around'
                            }}
                        >
                            <Stack direction="row" alignItems="center">
                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        flexDirection: 'column',
                                        mr: 0.4
                                    }}
                                >
                                    <Icon icon="lsicon:clothes-filled" />
                                </Box>
                                <Typography
                                    sx={{
                                        fontSize: 15,
                                        lineHeight: '20px',
                                        color: '#f2f2f2',
                                        flex: 'auto',
                                        overflow: 'hidden',
                                        whiteSpace: 'nowrap',
                                        textOverflow: 'ellipsis'
                                    }}
                                >
                                    FC Buzau
                                </Typography>
                            </Stack>
                            <Stack direction="row" alignItems="center">
                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        flexDirection: 'column',
                                        mr: 0.4
                                    }}
                                >
                                    <Icon icon="lsicon:clothes-filled" />
                                </Box>
                                <Typography
                                    sx={{
                                        fontSize: 15,
                                        lineHeight: '20px',
                                        color: '#f2f2f2',
                                        flex: 'auto',
                                        overflow: 'hidden',
                                        whiteSpace: 'nowrap',
                                        textOverflow: 'ellipsis'
                                    }}
                                >
                                    FC Buzau
                                </Typography>
                            </Stack>
                        </Stack>
                        <Stack sx={{ ml: 1 }}>
                            <Typography sx={{ fontSize: 16 }}>0</Typography>
                            <Typography sx={{ fontSize: 16 }}>0</Typography>
                        </Stack>
                    </Stack>

                    <Stack direction="row" sx={{ position: 'relative', alignItems: 'flex-start', mt: 0.8 }}>
                        <Stack direction="row" sx={{ flex: 'auto', flexWrap: 'wrap', justifyContent: 'center' }}>
                            <Typography sx={{ fontSize: 12, lineHeight: '16px', color: '#ccc' }}>
                                0 : 0, (0:0) 45`
                            </Typography>
                        </Stack>
                    </Stack>

                    <Stack
                        spacing={0.5}
                        direction="row"
                        alignItems="center"
                        justifyContent="center"
                        sx={{ position: 'relative', mt: 1, pt: 1, borderTop: '1px solid #a9a9c1' }}
                    >
                        <Box
                            component="i"
                            className="game-details-c-live-i-item-bc bc-i-head-t-head active"
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                width: '20%',
                                height: 32,
                                maxWidth: 63,
                                color: '#e9e9e9',
                                background: '#0006',
                                borderRadius: 1,
                                fontSize: 18,
                                cursor: 'pointer',
                                transition: 'color 0.24s, background 0.24s'
                            }}
                        />
                        <Box
                            component="i"
                            className="game-details-c-live-i-item-bc bc-i-stats active"
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                width: '20%',
                                height: 32,
                                maxWidth: 63,
                                color: '#e9e9e9',
                                background: '#0006',
                                borderRadius: 1,
                                fontSize: 18,
                                cursor: 'pointer',
                                transition: 'color 0.24s, background 0.24s'
                            }}
                        />
                        <Box
                            component="i"
                            className="game-details-c-live-i-item-bc bc-i-graph"
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                width: '20%',
                                height: 32,
                                maxWidth: 63,
                                color: '#e9e9e9',
                                background: '#0006',
                                borderRadius: 1,
                                fontSize: 18,
                                cursor: 'pointer',
                                transition: 'color 0.24s, background 0.24s'
                            }}
                        />
                    </Stack>
                </Box>
            </Box>
        </Box>
    );

    const renderMarketName = (
        <Stack
            direction="row"
            sx={{
                position: 'sticky',
                top: 0,
                boxShadow: (theme) => `0 0 0 2px ${theme.palette.background.default}`,
                background: 'background.default',
                zIndex: 1,
                borderRadius: 1,
                overflow: 'hidden'
            }}
        >
            <Box
                sx={{
                    position: 'absolute',
                    top: 0,
                    width: 1,
                    height: 36,
                    maxWidth: 36,
                    cursor: 'pointer',
                    zIndex: 3,
                    right: 0,
                    left: 0
                }}
            >
                <Box
                    sx={{
                        display: 'inline-block',
                        verticalAlign: 'top',
                        width: 37,
                        height: 36,
                        bgcolor: 'translucent.02',
                        position: 'absolute',
                        top: 0,
                        borderRadius: 0,
                        left: 0
                    }}
                >
                    <Box
                        component="i"
                        className="ss-icon-bc bc-i-search"
                        sx={{
                            display: 'inline-block',
                            verticalAlign: 'top',
                            width: 16,
                            height: 16,
                            position: 'absolute',
                            top: 0,
                            right: 0,
                            bottom: 0,
                            left: 0,
                            margin: 'auto',
                            fontSize: 16,
                            color: '#fffc'
                        }}
                    />
                </Box>
            </Box>

            <Stack
                direction="row"
                alignItems="center"
                sx={{
                    width: 1,
                    ml: 3.7,
                    position: 'relative',
                    willChange: 'scroll-position',
                    height: 36,
                    bgcolor: 'translucent.01',
                    overflowX: 'auto',
                    overflowY: 'hidden',
                    scrollbarWidth: 'none'
                }}
            >
                <Tabs
                    value={value}
                    onChange={handleChange}
                    variant="scrollable"
                    scrollButtons="auto"
                    TabIndicatorProps={{
                        sx: {
                            bgcolor: 'primary.main'
                        }
                    }}
                    sx={{
                        // width: 'calc(100% - 37px - 61px)',
                        width: '100%',
                        minHeight: 0
                    }}
                >
                    <Tab
                        label="Item One"
                        sx={{ minHeight: 36, m: '0px !important', px: 1.5, minWidth: 0, fontWeight: 500 }}
                    />
                    <Tab
                        label="Item One"
                        sx={{ minHeight: 36, m: '0px !important', px: 1.5, minWidth: 0, fontWeight: 500 }}
                    />
                    <Tab
                        label="Item One"
                        sx={{ minHeight: 36, m: '0px !important', px: 1.5, minWidth: 0, fontWeight: 500 }}
                    />
                    <Tab
                        label="Item One"
                        sx={{ minHeight: 36, m: '0px !important', px: 1.5, minWidth: 0, fontWeight: 500 }}
                    />
                    <Tab
                        label="Item One"
                        sx={{ minHeight: 36, m: '0px !important', px: 1.5, minWidth: 0, fontWeight: 500 }}
                    />
                    <Tab
                        label="Item One"
                        sx={{ minHeight: 36, m: '0px !important', px: 1.5, minWidth: 0, fontWeight: 500 }}
                    />
                    <Tab
                        label="Item One"
                        sx={{ minHeight: 36, m: '0px !important', px: 1.5, minWidth: 0, fontWeight: 500 }}
                    />
                </Tabs>
            </Stack>

            <Stack direction="row" alignItems="center" sx={{ px: 1, bgcolor: 'translucent.01', zIndex: 1 }}>
                <Box
                    component="i"
                    className="bc-i-one-block "
                    sx={{
                        color: 'translucent.03',
                        fontSize: 18,
                        cursor: 'pointer',
                        transition: 'color 0.24s'
                    }}
                />
                <Box
                    component="i"
                    className="bc-i-two-block active"
                    sx={{
                        ml: 0.5,
                        color: 'common.white',
                        fontSize: 18,
                        cursor: 'pointer',
                        transition: 'color 0.24s'
                    }}
                />
            </Stack>
        </Stack>
    );

    const renderMarket = (
        <Box>
            <Box sx={{ borderRadius: 1, position: 'relative' }}>
                <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    sx={{
                        borderTopWidth: 2,
                        px: 1,
                        borderStartStartRadius: 4,
                        borderStartEndRadius: 4,
                        py: 0.5,
                        bgcolor: 'translucent.05',
                        backgroundClip: 'padding-box',
                        cursor: 'pointer',
                        borderTop: '3px solid transparent'
                    }}
                >
                    <Stack direction="row" alignItems="center">
                        <Box
                            component="i"
                            className="sgm-market-g-h-fav-icon-bc bc-i-favorite "
                            sx={{ fontSize: 14, color: 'translucent.08' }}
                        />
                        <Typography sx={{ ml: 1, fontSize: 12, color: 'translucent.08' }}>Match Result</Typography>
                    </Stack>

                    <Stack direction="row" alignItems="center">
                        <Box
                            component="i"
                            className="sgm-market-g-h-link-icon-bc bc-i-link"
                            sx={{
                                fontSize: 14,
                                lineHeight: '14px',
                                color: 'translucent.08',
                                '&::after': {
                                    content: `'1'`,
                                    ml: 0.4
                                }
                            }}
                        />
                        <Box
                            component="i"
                            className="sgm-market-g-h-cashed-out-icon-bc bc-i-cashed-out"
                            sx={{
                                ml: 0.7,
                                pl: 0.8,
                                position: 'relative',
                                display: 'flex',
                                flexShrink: 0,
                                fontSize: 14,
                                lineHeight: 1,
                                color: '#109121',
                                '&::after': {
                                    left: 0,
                                    content: `""`,
                                    position: 'absolute',
                                    top: 0,
                                    width: '1px',
                                    height: 14,
                                    bgcolor: 'background.default'
                                }
                            }}
                        />
                        <Box
                            component="i"
                            className="sgm-market-g-h-toggle-icon-bc bc-i-small-arrow-up"
                            sx={{
                                ml: 0.7,
                                pl: 0.8,
                                position: 'relative',
                                display: 'flex',
                                flexShrink: 0,
                                fontSize: 11,
                                lineHeight: '14px',
                                color: 'translucent.08',
                                '&::after': {
                                    left: 0,
                                    content: `""`,
                                    position: 'absolute',
                                    top: 0,
                                    width: '1px',
                                    height: 14,
                                    bgcolor: 'background.default'
                                }
                            }}
                        />
                    </Stack>
                </Stack>
            </Box>
            <Stack direction="row" alignItems="center" spacing={0.1}>
                <OddButton oddName="Belgium" odd={1.54} change={1} />
                <OddButton oddName="Draw" odd={1.53} change={0} />
                <OddButton oddName="Italy" odd={1.52} change={-1} />
            </Stack>
        </Box>
    );

    return (
        <Box
            sx={{
                pr: 0.2,
                pl: 1,
                height: 1,
                flexShrink: 0,
                width: isFull ? 1 : '43%',
                overflowX: 'hidden',
                overflowY: 'scroll',
                willChange: 'scroll-position'
            }}
        >
            {renderScorBoard}
            {renderMarketName}

            <Box sx={{ py: 0.1 }}>
                <Stack
                    direction="row"
                    alignItems="center"
                    sx={{
                        bgcolor: 'translucent.04',
                        pl: 1,
                        pr: 1.5,
                        borderRadius: 1,
                        height: 32,
                        cursor: 'pointer'
                    }}
                >
                    <Typography
                        sx={{
                            flex: 'auto',
                            fontSize: 12,
                            fontWeight: 500,
                            color: 'common.white',
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis'
                        }}
                    >
                        Markets
                    </Typography>
                    <Box
                        component="i"
                        className="sgm-m-h-toggle-icon-bc bc-i-small-arrow-up"
                        sx={{ fontSize: 13, mb: 0.4, color: '#ffffffb3' }}
                    />
                </Stack>

                <Box
                    sx={{
                        display: isFull ? 'grid' : 'block',
                        gridTemplateColumns: '1fr 1fr',
                        columnGap: '5px',
                        rowGap: '2px'
                    }}
                >
                    {isFull ? (
                        <>
                            <Box sx={{ position: 'relative' }}>
                                {renderMarket}
                                {renderMarket}
                                {renderMarket}
                            </Box>
                            <Box sx={{ position: 'relative' }}>
                                {renderMarket}
                                {renderMarket}
                            </Box>
                        </>
                    ) : (
                        <Box sx={{ position: 'relative' }}>
                            {renderMarket}
                            {renderMarket}
                        </Box>
                    )}
                </Box>

                <Typography
                    sx={{
                        fontSize: 13,
                        lineHeight: '16px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: 1,
                        padding: '5vh 10px',
                        color: 'translucent.06',
                        textAlign: 'center'
                    }}
                >
                    The time display shown within live betting serves as an indicator. The company takes no
                    responsibility for the correctness and currentness of the displayed information like score or time.
                </Typography>
            </Box>
        </Box>
    );
};

export default EventDetail;
