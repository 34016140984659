// @mui
import { Button, FormControl, InputLabel, MenuItem, Select, Stack, TextField, Typography } from '@mui/material';
// import { DatePicker, PickersDay } from "@mui/x-date-pickers";
// // others
// import dayjs from "dayjs";

const names = [
    'Oliver Hansen',
    'Van Henry',
    'April Tucker',
    'Ralph Hubbard',
    'Omar Alexander',
    'Carlos Abbott',
    'Miriam Wagner',
    'Bradley Wilkerson',
    'Virginia Andrews',
    'Kelly Snyder'
];

const FilterBetHistory = () => {
    return (
        <>
            <Stack direction={{ md: 'row', xs: 'column' }} spacing={{ md: 0.5, xs: 1 }}>
                {/* <Stack direction="row" sx={{ flex: 2 }} gap={0.5}>
                    <FormControl
                        variant="filled"
                        sx={{
                            width: 1,
                            '.MuiPopover-paper': { bgcolor: 'translucent.01' }
                        }}
                    >
                        <DatePicker
                            defaultValue={dayjs()}
                            label="Range"
                            slots={{
                                day: StyledDay,
                                openPickerButton: StyledButton
                            }}
                            slotProps={{
                                openPickerIcon: { fontSize: 'medium' },
                                openPickerButton: { color: 'default' },
                                textField: {
                                    variant: 'filled',
                                    focused: true,
                                    color: 'secondary'
                                }
                            }}
                        />
                    </FormControl>
                    <FormControl
                        variant="filled"
                        sx={{
                            width: 1,
                            '.MuiPopover-paper': { bgcolor: 'translucent.01' }
                        }}
                    >
                        <DatePicker
                            defaultValue={dayjs()}
                            slots={{
                                openPickerButton: StyledButton,
                                day: StyledDay
                            }}
                            slotProps={{
                                openPickerIcon: { fontSize: 'medium' },
                                openPickerButton: { color: 'default' },
                                textField: {
                                    variant: 'filled',
                                    focused: true,
                                    color: 'secondary'
                                }
                            }}
                        />
                    </FormControl>
                </Stack> */}
                <FormControl
                    variant="filled"
                    sx={{
                        width: 1,
                        flex: 1,
                        bgcolor: 'translucent.01'
                    }}
                >
                    <TextField variant="filled" label="Bet ID" />
                </FormControl>
                <FormControl
                    variant="filled"
                    sx={{
                        width: 1,
                        flex: 1,
                        bgcolor: 'translucent.01'
                    }}
                >
                    <TextField variant="filled" label="Sport Name" />
                </FormControl>
                <FormControl
                    variant="filled"
                    sx={{
                        width: 1,
                        flex: 1,
                        '.MuiPopover-paper': { bgcolor: 'translucent.01' }
                    }}
                >
                    <InputLabel>Bet Type</InputLabel>
                    <Select
                        sx={{
                            bgcolor: 'translucent.04',
                            '& .MuiTypography-root': {
                                color: 'white !important',
                                fontSize: 14
                            }
                        }}
                    >
                        {names.map((name) => (
                            <MenuItem
                                sx={{
                                    bgcolor: 'translucent.04',
                                    marginBottom: '1px !important',
                                    minHeight: '35px'
                                }}
                                key={name}
                                value={name}
                            >
                                <Typography fontSize={12} color="textSecondary">
                                    {name}
                                </Typography>
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>

                <FormControl
                    variant="filled"
                    sx={{ width: 1, flex: 1, '.MuiPopover-paper': { bgcolor: 'translucent.01' } }}
                >
                    <InputLabel>Period</InputLabel>
                    <Select
                        sx={{
                            bgcolor: 'translucent.04',
                            '& .MuiTypography-root': {
                                color: 'white !important',
                                fontSize: 14
                            }
                        }}
                    >
                        {names.map((name) => (
                            <MenuItem
                                sx={{
                                    bgcolor: 'translucent.04',
                                    marginBottom: '1px !important',
                                    minHeight: '35px'
                                }}
                                key={name}
                                value={name}
                            >
                                <Typography fontSize={12} color="textSecondary">
                                    {name}
                                </Typography>
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Stack>
            <Stack direction="row" justifyContent="flex-end" sx={{ mt: 2 }}>
                <Button
                    variant="contained"
                    color="success"
                    sx={{ width: { md: 'auto', xs: 1 }, minWidth: 'calc(25% - 5px)' }}
                >
                    SHOW
                </Button>
            </Stack>
        </>
    );
};
export default FilterBetHistory;
