import { useState } from 'react';
// @mui
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Collapse, useTheme } from '@mui/material';
// config
import { RESULT_PAGES } from 'config';
// hook
import { useBoolean } from 'hooks/use-boolean';
import { useRouter } from 'routes/hook';
// components
import LiveFilter from './liveFilter';
import FinishFilter from './finishFilter';
// icon
import { Icon } from '@iconify/react';
type MatchGroupTypes = {
    index: number;
};

type MatchItemTypes = {};
const MatchItem = (props: MatchItemTypes) => {
    const theme = useTheme();
    const router = useRouter();
    return (
        <Stack
            width={1}
            gap={0.1}
            borderRadius={1}
            overflow="hidden"
            onClick={(e) => {
                router.push('123123123');
                e.stopPropagation();
            }}
        >
            <Stack minHeight={52} sx={{ bgcolor: 'translucent.01', p: 1, gap: 0.2 }}>
                <Stack direction="row" justifyContent="space-between">
                    <Typography fontSize={12}>Girona FC</Typography>
                    <Typography fontSize={12} sx={{ color: 'gold' }}>
                        1
                    </Typography>
                </Stack>
                <Stack direction="row" justifyContent="space-between">
                    <Typography fontSize={12}>Liverpool</Typography>
                    <Typography fontSize={12} sx={{ color: 'gold' }}>
                        2
                    </Typography>
                </Stack>
                <Stack direction="row" justifyContent="space-between">
                    <Typography color="textSecondary" fontSize={11}>
                        02:45
                    </Typography>
                </Stack>
            </Stack>
            <Stack
                flex={1}
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                sx={{
                    padding: '5px',
                    bgcolor: 'translucent.01'
                }}
            >
                <Stack direction="row" alignItems="center" gap={0.2}>
                    <Icon
                        icon="material-symbols:timer-outline"
                        style={{ width: 15, height: 15 }}
                        color={theme.palette.grey[500]}
                    />
                    <Typography color="textSecondary" fontSize={11}>
                        03.12.2024 | 02:45
                    </Typography>
                </Stack>

                <Stack direction="row" alignItems="center" gap={0.2}>
                    <Typography fontSize={12}>VIEW</Typography>
                    <Icon icon="lsicon:right-filled" width="18" height="18" />
                </Stack>
            </Stack>
        </Stack>
    );
};

const MatchGroupByLeague = (props: MatchGroupTypes) => {
    const isOpened = useBoolean(true);
    return (
        <Box
            sx={{
                bgcolor: 'translucent.01',
                transition: 'all 0.24s'
            }}
            onClick={isOpened.onToggle}
        >
            <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                sx={{
                    height: 33,
                    px: 1,
                    cursor: 'pointer',
                    borderRadius: 1,
                    bgcolor: 'background.default',
                    boxShadow: (theme) => `inset 0 0 0 18px ${theme.palette.translucent['01']}`,
                    transition: 'background  0.24s',
                    // -------------------------------
                    position: 'sticky',
                    top: 0,
                    zIndex: 1
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flex: '1',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        height: 1
                    }}
                >
                    <Typography
                        sx={{
                            fontSize: 12,
                            mr: 1,
                            textTransform: 'capitalize',
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis'
                        }}
                    >
                        AFC Champions League Elite
                    </Typography>
                </Box>
                <Box
                    component="i"
                    className="sp-s-l-h-arrow-icon-bc bc-i-small-arrow-down"
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        fontSize: 11,
                        borderRadius: 1,
                        lineHeight: 23,
                        transition: 'color 0.24s, background 0.24s',
                        color: isOpened.value ? 'common.white' : 'translucent.06',
                        ...(isOpened.value && {
                            transform: 'rotate(180deg)'
                        })
                    }}
                />
            </Stack>
            <Collapse in={isOpened.value}>
                <Stack
                    px={0.5}
                    sx={{
                        gap: 1,
                        pb: 0.5
                    }}
                >
                    {new Array(4).fill(1).map((_, index) => (
                        <MatchItem key={index} />
                    ))}
                </Stack>
            </Collapse>
        </Box>
    );
};

const Result = () => {
    const [selectedTab, setSelectedTab] = useState<string>(RESULT_PAGES[0]);
    const SelectTab = (tab: string) => {
        setSelectedTab(tab);
    };

    return (
        <Box
            sx={{
                width: 1,
                gridArea: 'content'
            }}
        >
            <Stack sx={{ height: 1, pb: 1, pt: 1.5 }}>
                <Stack
                    direction="row"
                    alignItems="center"
                    spacing={0.1}
                    sx={{
                        width: 1,
                        px: 0.5,
                        height: 34
                    }}
                >
                    <Button
                        onClick={() => SelectTab(RESULT_PAGES[0])}
                        sx={{
                            width: 1,
                            borderStartEndRadius: 0,
                            borderEndEndRadius: 0,
                            bgcolor: selectedTab === RESULT_PAGES[0] ? 'translucent.02' : 'translucent.01',
                            boxShadow:
                                selectedTab === RESULT_PAGES[0]
                                    ? (theme) => `inset 0 -1px 0 0px ${theme.palette.primary.main}`
                                    : 'unset',
                            '&:hover': {
                                bgcolor: selectedTab === RESULT_PAGES[0] ? 'translucent.02' : 'translucent.01',
                                boxShadow:
                                    selectedTab === RESULT_PAGES[0]
                                        ? (theme) => `inset 0 -1px 0 0px ${theme.palette.primary.main}`
                                        : 'unset'
                            }
                        }}
                    >
                        Live
                    </Button>
                    <Button
                        onClick={() => SelectTab(RESULT_PAGES[1])}
                        sx={{
                            width: 1,
                            borderStartStartRadius: 0,
                            borderEndStartRadius: 0,
                            bgcolor: selectedTab === RESULT_PAGES[1] ? 'translucent.02' : 'translucent.01',
                            boxShadow:
                                selectedTab === RESULT_PAGES[1]
                                    ? (theme) => `inset 0 -1px 0 0px ${theme.palette.primary.main}`
                                    : 'unset',
                            '&:hover': {
                                bgcolor: selectedTab === RESULT_PAGES[1] ? 'translucent.02' : 'translucent.01',
                                boxShadow:
                                    selectedTab === RESULT_PAGES[1]
                                        ? (theme) => `inset 0 -1px 0 0px ${theme.palette.primary.main}`
                                        : 'unset'
                            }
                        }}
                    >
                        Finished
                    </Button>
                </Stack>
                {selectedTab === RESULT_PAGES[0] ? <LiveFilter /> : <FinishFilter />}

                <Stack sx={{ width: 1, minHeight: '100%', mt: 0.1 }} spacing={0.5}>
                    {new Array(5).fill(1).map((_, i) => (
                        <MatchGroupByLeague key={i} index={i} />
                    ))}
                </Stack>

                {/* <Stack direction="row" sx={{ height: 1 }}>
                    <Stack direction="row" sx={{ height: 1, width: 1, position: 'relative' }}>
                        <Box
                            sx={{
                                width: 1,
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                height: 1,
                                pr: 0.2,
                                willChange: 'scroll-position',
                                overflowX: 'hidden',
                                overflowY: 'scroll'
                            }}
                        >
                            <Stack sx={{ flexWrap: 'wrap', boxShadow: '0 2px 4px #00000026' }}>
                                <Stack
                                    direction="row"
                                    alignItems="center"
                                    sx={{
                                        borderRadius: 1,
                                        width: 1,
                                        height: 34,
                                        px: 0.7,
                                        cursor: 'pointer',
                                        bgcolor: 'translucent.04'
                                    }}
                                >
                                    <Box
                                        component="img"
                                        src="https://statistics.bcapps.org/images/c/s/1/3034.png"
                                        sx={{ width: 18, height: 18, mr: 0.8 }}
                                    />
                                    <Typography
                                        className="ellipsis"
                                        sx={{ fontSize: 12, flex: 'auto', lineHeight: 14, fontWeight: 500 }}
                                    >
                                        U21 International Friendlies
                                    </Typography>
                                    <IconButton>
                                        <Box
                                            component="i"
                                            className="competition-icon-arrow-bc bc-i-small-arrow-up"
                                            sx={{ fontSize: 11, color: '#fffc' }}
                                        />
                                    </IconButton>
                                </Stack>
                            </Stack>

                            <Stack sx={{ p: 0.5, bgcolor: 'translucent.05', cursor: 'pointer' }}>
                                <Stack
                                    sx={{ py: 1, px: 0.5, borderRadius: '4px 4px 0px 0px', bgcolor: 'translucent.01' }}
                                >
                                    <Stack direction="row" alignItems="center">
                                        <Typography
                                            className="ellipsis"
                                            sx={{ flex: 'auto', fontSize: 11, color: '#ffffffe6' }}
                                        >
                                            Benin
                                        </Typography>
                                        <Typography
                                            component="span"
                                            sx={{ ml: 1, fontSize: 12, fontWeight: 500, color: 'gold' }}
                                        >
                                            1
                                        </Typography>
                                    </Stack>
                                    <Stack direction="row" alignItems="center" sx={{ mt: 0.5 }}>
                                        <Typography
                                            className="ellipsis"
                                            sx={{ flex: 'auto', fontSize: 11, color: '#ffffffe6' }}
                                        >
                                            Benin
                                        </Typography>
                                        <Typography
                                            component="span"
                                            sx={{ ml: 1, fontSize: 12, fontWeight: 500, color: 'gold' }}
                                        >
                                            1
                                        </Typography>
                                    </Stack>
                                    <Typography
                                        className="ellipsis"
                                        sx={{ mt: 0.8, flex: 'auto', fontSize: 10, color: '#ffffff80' }}
                                    >
                                        1:0 (1:0)
                                    </Typography>
                                </Stack>
                                <Stack
                                    direction="row"
                                    alignItems="center"
                                    justifyContent="space-between"
                                    sx={{
                                        mt: 0.1,
                                        px: 0.5,
                                        height: 24,
                                        borderRadius: ' 0px 0px 4px 4px',
                                        bgcolor: 'translucent.01'
                                    }}
                                >
                                    <Stack direction="row" alignItems="center" sx={{ flex: 'auto' }}>
                                        <Box
                                            component="i"
                                            className="results-footer-icon-bc bc-i-watch"
                                            sx={{ fontSize: 14, ml: 0.2, color: '#ffffff80' }}
                                        />
                                        <Typography sx={{ px: 0.4, fontSize: 10, color: '#ffffff80' }}>
                                            15.11.2024
                                        </Typography>
                                        <Box
                                            sx={{
                                                height: 'auto',
                                                width: '1px',
                                                mx: 0.4,
                                                bgcolor: 'background.default'
                                            }}
                                        />
                                        <Typography sx={{ px: 0.4, fontSize: 10, color: '#ffffff80' }}>
                                            04:00
                                        </Typography>
                                    </Stack>
                                    <Stack direction="row" alignItems="center" spacing={0.4}>
                                        <Typography sx={{ fontSize: 12, color: '#ffffffb3', mr: 0.8 }}>VIEW</Typography>
                                        <Box
                                            component="i"
                                            className="results-footer-view-icon-bc bc-i-small-arrow-right"
                                            sx={{
                                                fontSize: 10,
                                                height: 8,
                                                width: 8
                                            }}
                                        />
                                    </Stack>
                                </Stack>
                            </Stack>
                        </Box>
                    </Stack>

                    <Stack direction="row" sx={{ height: 1, width: 1, position: 'relative' }}>
                        <Box
                            sx={{
                                width: 1,
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                height: 1,
                                pr: 0.2,
                                willChange: 'scroll-position',
                                overflowX: 'hidden',
                                overflowY: 'scroll'
                            }}
                        >
                            <Stack sx={{ cursor: 'pointer', p: 0.5 }}>
                                <Stack
                                    direction="row"
                                    alignItems="center"
                                    sx={{
                                        borderRadius: 1,
                                        width: 1,
                                        height: 34,
                                        px: 0.7,
                                        cursor: 'pointer',
                                        bgcolor: 'translucent.01'
                                    }}
                                >
                                    <Typography
                                        className="ellipsis"
                                        sx={{ fontSize: 12, flex: 'auto', lineHeight: 14, fontWeight: 500 }}
                                    >
                                        Double Chance
                                    </Typography>
                                    <IconButton>
                                        <Box
                                            component="i"
                                            className="competition-icon-arrow-bc bc-i-small-arrow-up"
                                            sx={{ fontSize: 11, color: '#fffc' }}
                                        />
                                    </IconButton>
                                </Stack>

                                <Box sx={{ borderRadius: '0px 0px 4px 4px', overflow: 'hidden' }}>
                                    <Stack
                                        direction="row"
                                        alignItems="center"
                                        sx={{
                                            height: 34,
                                            mt: 0.1,
                                            fontSize: 12,
                                            bgcolor: 'translucent.05',
                                            color: '#ffffff80',
                                            px: 1
                                        }}
                                    >
                                        W2
                                    </Stack>
                                    <Stack
                                        direction="row"
                                        alignItems="center"
                                        sx={{
                                            height: 34,
                                            mt: 0.1,
                                            fontSize: 12,
                                            bgcolor: 'translucent.05',
                                            color: '#ffffff80',
                                            px: 1
                                        }}
                                    >
                                        W2
                                    </Stack>
                                </Box>
                            </Stack>
                        </Box>
                    </Stack>
                </Stack> */}
            </Stack>
        </Box>
    );
};

export default Result;
