// @mui
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Collapse from '@mui/material/Collapse';
import Typography from '@mui/material/Typography';
import { useBoolean } from 'hooks/use-boolean';
// others
import { US } from 'country-flag-icons/react/3x2';
// config
import { Divider, IconButton } from '@mui/material';
import { Icon } from '@iconify/react';
// hooks
import { useRouter } from 'routes/hook';

const LeagueItem = () => {
    const router = useRouter();
    return (
        <Box
            onClick={(e) => {
                router.push('USA/3025/mls');
                e.stopPropagation();
            }}
            sx={{
                px: 0.5,
                borderRadius: 1,
                transition: 'all 0.24s',
                bgcolor: 'translucent.01'
            }}
        >
            <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                sx={{
                    height: 35,
                    cursor: 'pointer',
                    borderRadius: 1,
                    transition: 'background 0.24s'
                }}
            >
                <IconButton sx={{ padding: '2px' }} onClick={(e) => e.stopPropagation()}>
                    <Icon icon="mingcute:star-line" width="20" height="20" />
                </IconButton>
                <Divider
                    orientation="vertical"
                    sx={{
                        height: '80%',
                        background: '#000c24',
                        borderRightWidth: '1.5px',
                        ml: 0.5
                    }}
                />
                <Stack sx={{ justifyContent: 'center', flex: 'auto', height: 1, overflow: 'hidden', ml: 1 }}>
                    <Typography
                        sx={{
                            mr: 1,
                            lineHeight: 1,
                            color: '#fffc',
                            fontSize: 13,
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis',
                            textTransform: 'capitalize'
                        }}
                    >
                        UEFA Champions League. Outright
                    </Typography>
                </Stack>
                <Typography component="span" sx={{ color: 'translucent.03', fontSize: 12 }}>
                    1
                </Typography>
                <Box
                    component="i"
                    className={`sp-s-l-h-arrow-icon-bc bc-i-small-arrow-right`}
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: 24,
                        height: 24,
                        ml: 1,
                        fontSize: 11,
                        borderRadius: 4,
                        lineHeight: 23,
                        color: 'translucent.06',
                        transition: 'color 0.24s, background 0.24s'
                    }}
                />
            </Stack>
        </Box>
    );
};

const CountryItem = ({ index }: { index: number }) => {
    const isOpened = useBoolean();

    return (
        <Box
            sx={{
                bgcolor: 'translucent.01',
                borderRadius: 1,
                transition: 'all 0.24s'
            }}
            onClick={isOpened.onToggle}
        >
            <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                sx={{
                    height: 36,
                    px: 1,
                    cursor: 'pointer',
                    borderRadius: 1,
                    bgcolor: 'background.default',
                    boxShadow: (theme) => `inset 0 0 0 18px ${theme.palette.translucent['01']}`,
                    transition: 'background  0.24s',
                    // -------------------------------
                    position: 'sticky',
                    top: 0,
                    zIndex: 1
                }}
            >
                <US title="United States" style={{ width: 25, height: 15, marginRight: 10 }} />
                <Box
                    sx={{
                        display: 'flex',
                        flex: 'auto',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        height: 1
                    }}
                >
                    <Typography
                        sx={{
                            fontSize: 14,
                            mr: 1,
                            textTransform: 'capitalize',
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis'
                        }}
                    >
                        USA
                    </Typography>
                </Box>
                <Typography component="span" sx={{ color: 'translucent.03', fontSize: 13 }}>
                    18
                </Typography>
                <Box
                    component="i"
                    className="sp-s-l-h-arrow-icon-bc bc-i-small-arrow-down"
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: 24,
                        height: 24,
                        ml: 1,
                        fontSize: 11,
                        borderRadius: 1,
                        lineHeight: 23,
                        transition: 'color 0.24s, background 0.24s',
                        color: isOpened.value ? 'common.white' : 'translucent.06',
                        ...(isOpened.value && {
                            // bgcolor: COLOR_LIST[index],
                            transform: 'rotate(180deg)'
                        })
                    }}
                />
            </Stack>
            <Collapse in={isOpened.value}>
                <Stack
                    px={0.5}
                    sx={{
                        gap: 0.2,
                        pb: 0.5
                    }}
                >
                    {new Array(4).fill(1).map((_, index) => (
                        <LeagueItem key={index} />
                    ))}
                </Stack>
            </Collapse>
        </Box>
    );
};

const MobileUpcomingList = () => {
    return (
        <Box
            sx={{
                flex: 'auto',
                position: 'relative',
                py: 0.5
            }}
        >
            <Stack sx={{ width: 1, minHeight: '100%', pr: 0.2, mb: 0 }} spacing={0.2}>
                {new Array(17).fill(1).map((_, i) => (
                    <CountryItem key={i} index={i} />
                ))}
            </Stack>
        </Box>
    );
};

export default MobileUpcomingList;
