import {
    Box,
    Button,
    Typography,
    MenuList,
    MenuItem,
    Collapse,
    TextField,
    useMediaQuery,
    useTheme,
    Stack
} from '@mui/material';

// others
import { Icon } from '@iconify/react';
// hook
import { useBoolean } from 'hooks/use-boolean';
// types
import { childrenProp, menuNapProps } from 'types/sports/profile';

const menuList = [
    {
        icon: 'solar:user-circle-bold',
        text: 'My Profile',
        value: 'profile',
        children: [
            { text: 'Personal Detail', value: 'personal-detail' },
            { text: 'Change Password', value: 'change-password' }
        ]
    },
    {
        icon: 'ri:money-dollar-circle-line',
        text: 'Balance Management',
        value: 'balance-management',
        children: [
            { text: 'Deposit', value: 'deposit' },
            { text: 'Withdraw', value: 'withdraw' },
            { text: 'Transaction History', value: 'transaction-history' },
            { text: 'Info', value: 'info' }
        ]
    },
    {
        icon: 'ri:history-fill',
        text: 'Bet History',
        value: 'bet-history',
        children: [
            { text: 'All', value: 'all_bets' },
            { text: 'Open bets', value: 'open_bets' },
            { text: 'Won', value: 'won_bets' },
            { text: 'Lost', value: 'lost_bets' }
        ]
    },
    {
        icon: 'mingcute:gift-line',
        text: 'Bonuses',
        value: 'bonus',
        children: [
            { text: 'Sport Bonus', value: 'sport-bonus' },
            { text: 'Casino Bonus', value: 'casino-bonus' },
            { text: 'Bonus History', value: 'bonus-history' }
        ]
    },
    {
        icon: 'lets-icons:message-duotone',
        text: 'Messages',
        value: 'message',
        children: [
            { text: 'Inbox', value: 'message_inbox' },
            { text: 'Sent', value: 'message_sent' },
            { text: 'New', value: 'message_new' }
        ]
    }
];
const MenuNavigation = (props: menuNapProps | null) => {
    const isOpened = useBoolean(
        props?.menu?.children?.findIndex((item) => item.value === props?.activeItem?.value) && -1 < 0 ? false : true
    );
    return (
        <Box onClick={isOpened.onToggle} sx={{ borderRadius: 1, transition: 'all 0.24s' }}>
            <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                sx={{
                    height: 50,
                    px: 1,
                    cursor: 'pointer',
                    borderRadius: 1,
                    bgcolor: 'translucent.04',
                    transition: 'background  0.24s'
                }}
            >
                <Stack direction="row" gap={1}>
                    <Icon icon={props?.menu.icon || ''} width={22} height={22} />
                    <Typography
                        sx={{
                            fontSize: 13,
                            mr: 1,
                            textTransform: 'uppercase',
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis'
                        }}
                    >
                        {props?.menu.text}
                    </Typography>
                </Stack>
                <Box
                    component="i"
                    className="sp-s-l-h-arrow-icon-bc bc-i-small-arrow-down"
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: 24,
                        height: 24,
                        ml: 1,
                        fontSize: 11,
                        borderRadius: 1,
                        lineHeight: 23,
                        transition: 'color 0.24s, background 0.24s',
                        color: isOpened.value ? 'common.white' : 'translucent.06',
                        ...(isOpened.value && {
                            transform: 'rotate(180deg)'
                        })
                    }}
                />
            </Stack>
            <Collapse in={isOpened.value}>
                <Stack
                    gap={0.5}
                    p={1}
                    sx={{
                        bgcolor: 'translucent.01',
                        borderTop: 'solid 1px',
                        borderTopColor: 'translucent.01'
                    }}
                >
                    {props?.menu.children && (
                        <MenuList>
                            {props?.menu?.children?.map((child, i) => (
                                <MenuItem
                                    key={i}
                                    sx={{
                                        fontSize: 12,
                                        lineHeight: '30px',
                                        borderLeft: 'solid 2px',
                                        padding: 0,
                                        pl: '10px',
                                        borderTopLeftRadius: 0,
                                        borderBottomLeftRadius: 0,
                                        mb: '0 !important',
                                        borderColor:
                                            props?.activeItem?.value === child.value
                                                ? 'primary.main'
                                                : 'translucent.01',
                                        ...(props?.activeItem?.value === child.value && {
                                            backgroundImage: 'linear-gradient(to right, #000c244d 0%, #000c241a 100%)'
                                        }),
                                        '&:active': {
                                            backgroundImage: 'linear-gradient(to right, #000c244d 0%, #000c241a 100%)'
                                        }
                                    }}
                                    onClick={(e) => {
                                        props?.onChange(child);
                                        e.stopPropagation();
                                    }}
                                >
                                    {child.text}
                                </MenuItem>
                            ))}
                        </MenuList>
                    )}
                </Stack>
            </Collapse>
        </Box>
    );
};

const DeskMenu = ({
    activeItem,
    setActiveItem
}: {
    activeItem: childrenProp | null;
    setActiveItem: (value: childrenProp) => void;
}) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const handleChange = (panel: childrenProp) => {
        setActiveItem(panel);
    };

    return (
        <Stack direction="column" gap={1}>
            <Stack direction="column" gap={isMobile ? 0.1 : 1}>
                {menuList.map((menu, i) => (
                    <MenuNavigation key={i} menu={menu} onChange={handleChange} activeItem={activeItem} />
                ))}

                <Stack direction="row" p={1.5} justifyContent="center">
                    <Box
                        display="flex"
                        gap={1}
                        sx={{
                            bgcolor: 'translucent.01',
                            p: 1,
                            borderRadius: 1
                        }}
                    >
                        <TextField
                            variant="filled"
                            label="Promo Code"
                            sx={{
                                width: '60%',
                                marginInlineEnd: '10px'
                            }}
                        />
                        <Button
                            variant="contained"
                            color="success"
                            disabled
                            sx={{
                                width: 'auto'
                            }}
                        >
                            APPLY
                        </Button>
                    </Box>
                </Stack>
                <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    sx={{
                        height: 50,
                        px: 1,
                        cursor: 'pointer',
                        borderRadius: 1,
                        bgcolor: 'translucent.05',
                        '&:hover': {
                            bgcolor: 'translucent.01'
                        }
                    }}
                >
                    <Stack direction="row" gap={1} alignItems="center">
                        <Icon icon="ic:twotone-logout" width="24" height="24" />
                        <Typography
                            sx={{
                                fontSize: 13,
                                mr: 1,
                                textTransform: 'uppercase',
                                whiteSpace: 'nowrap',
                                textOverflow: 'ellipsis'
                            }}
                        >
                            Log out
                        </Typography>
                    </Stack>
                </Stack>
            </Stack>
        </Stack>
    );
};
export default DeskMenu;
