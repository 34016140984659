import Box from '@mui/material/Box';
// hooks
import { useBoolean } from 'hooks/use-boolean';
//
import EventDetail from './EventDetail';
import EventList from './EventList';

const EventView = ({ isLive }: { isLive: boolean }) => {
    const fullList = useBoolean();
    const multiColumn = useBoolean();

    return (
        <Box sx={{ ml: 1, flex: 'auto', height: 1, position: 'relative' }}>
            <Box
                sx={{
                    display: 'flex',
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: 1,
                    height: 1
                }}
            >
                {!isLive && (
                    <EventList
                        fullList={fullList.value}
                        multiColumn={multiColumn.value}
                        changeFullList={fullList.onToggle}
                        changeMultiColumn={multiColumn.onToggle}
                    />
                )}
                <EventDetail isFull={isLive} />
            </Box>
        </Box>
    );
};

export default EventView;
