import React from 'react';
// @mui
import {
    Box,
    Button,
    Typography,
    DialogContent,
    Dialog,
    IconButton,
    Stack,
    Grid2,
    Avatar,
    Divider,
    useMediaQuery,
    useTheme,
    Slide,
    AppBar,
    Toolbar,
    Tabs,
    Tab
} from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import { Icon } from '@iconify/react';

import Logo from 'components/Logo/logo';
import DeskMenu from 'components/Profile/menu/DeskMenu';
import MobileMenu from 'components/Profile/menu/MobileMenu';

import { childrenProp, IMenu, IMenuType } from 'types/sports/profile';
import { fNumber } from 'utils/format-number';

// --- Profile ----
import { PasswordChangeView, PersonalDetailView } from './profile';
// --- Bet History ----
import AllBetHistory from './bet-history/all';
import OpenBetHistory from './bet-history/open-bet';
import LostBetHistory from './bet-history/lost';
import WonBetHistory from './bet-history/won';
// --- Messages ----
import MessageInbox from './messages/inbox';

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<unknown>;
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction="left" ref={ref} {...props} />;
});

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`
    };
}

const AccountSettingDialog = ({
    status,
    mainItem,
    onClose
}: {
    status: boolean;
    mainItem: childrenProp;
    onClose: () => void;
}) => {
    const [value, setValue] = React.useState(0);
    const [selected, setSelected] = React.useState<IMenuType | null>(null);

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const [componentToRender, setComponentToRender] = React.useState<JSX.Element>();
    const [activeItem, setActiveItem] = React.useState<childrenProp | null>(
        isMobile ? null : { text: 'Personal Detail', value: 'personal-detail' }
    );

    React.useEffect(() => {
        if (mainItem && mainItem.value !== 'personal-detail') {
            setActiveItem(mainItem);
        }
    }, [mainItem]);

    React.useEffect(() => {
        if (activeItem) {
            // Profile
            if (activeItem.value === 'personal-detail') {
                setComponentToRender(<PersonalDetailView />);
            } else if (activeItem.value === 'change-password') {
                setComponentToRender(<PasswordChangeView />);
            }
            // Bet-History
            else if (activeItem.value === 'all_bets') {
                setComponentToRender(<AllBetHistory />);
            } else if (activeItem.value === 'open_bets') {
                setComponentToRender(<OpenBetHistory />);
            } else if (activeItem.value === 'won_bets') {
                setComponentToRender(<WonBetHistory />);
            } else if (activeItem.value === 'lost_bets') {
                setComponentToRender(<LostBetHistory />);
            }
            // Messages
            else if (activeItem.value === 'message_inbox') {
                setComponentToRender(<MessageInbox />);
            } else if (activeItem.value === 'message_sent') {
                setComponentToRender(<MessageInbox />);
            } else if (activeItem.value === 'message_new') {
                setComponentToRender(<MessageInbox />);
            }
        }
    }, [activeItem]);

    React.useEffect(() => {
        if (selected && activeItem && activeItem.value !== '') {
            setOpenDetailModal(true);
        }
    }, [selected, activeItem]);

    const closeDialog = () => {
        setActiveItem(null);
        onClose();
    };

    const getActiveIndex = () => {
        if (!selected || !selected.children) return 0;
        const index = selected.children.findIndex((child) => child.text === activeItem?.text);
        return index !== -1 ? index : 0;
    };

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    const closeDetailModal = () => {
        setOpenDetailModal(false);
        setActiveItem(null);
    };

    const [openDetailModal, setOpenDetailModal] = React.useState<boolean>(false);

    return (
        <Dialog
            open={status}
            maxWidth="xl"
            scroll="paper"
            onClose={closeDialog}
            fullScreen={isMobile}
            fullWidth
            sx={{
                '& .MuiPaper-root': {
                    md: {
                        height: '100%',
                        maxHeight: 'calc(90% - 64px)',
                        width: 'calc(98% - 64px)'
                    }
                }
            }}
        >
            <DialogContent sx={{ px: 0, mb: 2 }}>
                <Grid2 container height={1}>
                    {isMobile && (
                        <Stack
                            direction="row"
                            justifyContent="space-between"
                            sx={{
                                width: 1,
                                height: '46px',
                                borderBottom: 'solid 1px',
                                borderBottomColor: 'translucent.04'
                            }}
                        >
                            <Stack direction="row" alignItems="center" sx={{ flex: 'auto', height: '100%' }}>
                                <Logo />
                            </Stack>
                            <IconButton color="inherit" edge="start" onClick={closeDialog}>
                                <Icon icon="ic:outline-close" width="24" height="24" />
                            </IconButton>
                        </Stack>
                    )}
                    <Grid2
                        size={{ xs: 12, md: 3 }}
                        display="flex"
                        flexDirection={'column'}
                        sx={{
                            bgcolor: 'translucent.08',
                            minHeight: '100%',
                            height: '100%',
                            overflowY: 'scroll',
                            p: 1,
                            px: isMobile ? 0.5 : 1,
                            gap: 1
                            // gap: isMobile ? 0.5:1
                        }}
                    >
                        <Stack flexDirection={isMobile ? 'column-reverse' : 'column'} gap={1}>
                            <Stack direction="row" justifyContent="space-between" width={1}>
                                <Stack direction="row" alignItems="center" gap={0.5}>
                                    <Avatar>ST</Avatar>
                                    <Stack>
                                        <Typography fontSize={14}>Spahich Damir</Typography>
                                        <Stack direction="row" alignItems="center" gap={0.5}>
                                            <Typography color="textSecondary" fontSize={12}>
                                                Spahich Damir
                                            </Typography>
                                            <IconButton sx={{ p: 0 }}>
                                                <Icon icon="solar:copy-linear" width="14" height="14" />
                                            </IconButton>
                                        </Stack>
                                    </Stack>
                                </Stack>
                            </Stack>
                            <Box
                                width={1}
                                sx={{
                                    overflowX: 'auto',
                                    overflowY: 'hidden',
                                    '& ::-webkit-scrollbar': {
                                        display: 'none'
                                    },
                                    '& ::-moz-scrollbar': {
                                        display: 'none'
                                    },
                                    scrollbarWidth: 'none',
                                    msOverflowStyle: 'none'
                                }}
                            >
                                <Stack
                                    direction={isMobile ? 'row' : 'column'}
                                    sx={{
                                        width: '100%',
                                        overflow: 'auto',
                                        position: 'relative'
                                    }}
                                    gap={1}
                                >
                                    <Stack
                                        px={2}
                                        py={1}
                                        borderRadius={1}
                                        sx={{
                                            background: '#109121',
                                            boxShadow: '0 1px 4px 0 #000c2480',
                                            position: 'relative',
                                            overflow: 'hidden',
                                            minWidth: '80%',
                                            gap: 1,
                                            '&:before': {
                                                position: 'absolute',
                                                content: `""`,
                                                top: 0,
                                                left: 0,
                                                width: '100%',
                                                height: '100%',
                                                opacity: 0.9,
                                                background:
                                                    'radial-gradient(circle at -14% 113%, #ffffff4d, #ffffff00 135%)'
                                            }
                                        }}
                                    >
                                        <Icon
                                            icon="raphael:dollar"
                                            style={{
                                                position: 'absolute',
                                                right: '-10px',
                                                top: '-10px',
                                                opacity: 0.1
                                            }}
                                            width="80"
                                            height="80"
                                        />

                                        <Stack
                                            direction="row"
                                            justifyContent="space-between"
                                            alignItems="center"
                                            gap={1}
                                        >
                                            <Stack direction="column" width={1}>
                                                <Typography>Main Balance</Typography>
                                                <Typography variant="h6">{fNumber(0)}&nbsp;$</Typography>
                                            </Stack>
                                            <IconButton
                                                sx={{
                                                    borderRadius: 0.5,
                                                    bgcolor: 'translucent.02',
                                                    py: 0.7,
                                                    '&:hover': {
                                                        bgcolor: 'translucent.01'
                                                    }
                                                }}
                                            >
                                                <Icon icon="solar:eye-bold" width="22" height="22" color="white" />
                                            </IconButton>
                                        </Stack>

                                        <Stack
                                            direction="row"
                                            gap={1}
                                            sx={{
                                                position: 'relative'
                                            }}
                                        >
                                            <Button
                                                fullWidth
                                                variant="contained"
                                                startIcon={<Icon icon="basil:wallet-solid" width="24" height="24" />}
                                                sx={{
                                                    bgcolor: 'translucent.02',
                                                    color: 'white',
                                                    textTransform: 'uppercase',
                                                    '&:hover': { bgcolor: 'translucent.01' }
                                                }}
                                            // onClick={() => setActiveItem('deposit')}
                                            >
                                                Deposit
                                            </Button>
                                            <Button
                                                fullWidth
                                                variant="contained"
                                                startIcon={
                                                    <Icon icon="hugeicons:bitcoin-withdraw" width="24" height="24" />
                                                }
                                                sx={{
                                                    bgcolor: 'translucent.02',
                                                    color: 'white',
                                                    textTransform: 'uppercase',
                                                    '&:hover': { bgcolor: 'translucent.01' }
                                                }}
                                            // onClick={() => setActiveItem('withdraw')}
                                            >
                                                Withdraw
                                            </Button>
                                        </Stack>
                                    </Stack>

                                    <Stack
                                        px={2}
                                        py={1}
                                        minWidth={'80%'}
                                        borderRadius={1}
                                        sx={{
                                            background: '#aa7f00',
                                            boxShadow: '0 1px 4px 0 #000c2480',
                                            position: 'relative',
                                            overflow: 'hidden',
                                            gap: 0.21,
                                            '&:before': {
                                                position: 'absolute',
                                                content: `""`,
                                                top: 0,
                                                left: 0,
                                                width: '100%',
                                                height: '100%',
                                                opacity: 0.9,
                                                background:
                                                    'radial-gradient(circle at -14% 113%, #ffffff4d, #ffffff00 135%)'
                                            }
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                position: 'absolute',
                                                right: '-10px',
                                                top: '-10px',
                                                opacity: 0.1,
                                                fontSize: '70px'
                                            }}
                                            component="i"
                                            className="user-profile-nav-icon bc-i-promotion"
                                        />
                                        <Stack
                                            direction="row"
                                            justifyContent="space-between"
                                            alignItems="center"
                                            gap={1}
                                        >
                                            <Stack direction="column" width={1}>
                                                <Typography>Total Bonus money</Typography>
                                                <Typography variant="h6">{fNumber(0)}&nbsp;$</Typography>
                                            </Stack>
                                        </Stack>
                                        <Divider sx={{ background: '#fff3' }} />
                                        <Stack
                                            direction="row"
                                            gap={1}
                                            sx={{
                                                position: 'relative',
                                                justifyContent: 'space-between'
                                            }}
                                        >
                                            <Typography fontSize={12}>Bonus Balance</Typography>
                                            <Typography fontSize={12} fontWeight="bold">
                                                0.00 $
                                            </Typography>
                                        </Stack>
                                    </Stack>
                                </Stack>
                            </Box>
                        </Stack>

                        {isMobile ? (
                            <MobileMenu
                                setActiveItem={(value: childrenProp) => setActiveItem(value)}
                                selected={selected}
                                setSelected={setSelected}
                            />
                        ) : (
                            <DeskMenu
                                activeItem={activeItem}
                                setActiveItem={(value: childrenProp) => setActiveItem(value)}
                            />
                        )}
                    </Grid2>
                    {!isMobile && (
                        <Grid2 size={{ xs: 12, md: 9 }}>
                            <Stack
                                direction="row"
                                justifyContent="space-between"
                                width={1}
                                sx={{
                                    bgcolor: 'translucent.01',
                                    px: 1,
                                    pl: 2,
                                    borderBottom: 'solid 1px',
                                    borderBottomColor: 'translucent.05',
                                    alignItems: 'center'
                                }}
                            >
                                <Typography fontSize={14}>{activeItem?.text}</Typography>
                                <IconButton color="inherit" edge="start" onClick={closeDialog}>
                                    <Icon icon="ic:outline-close" width="24" height="24" />
                                </IconButton>
                            </Stack>
                            {componentToRender}
                        </Grid2>
                    )}
                    {isMobile && (
                        <Dialog
                            fullScreen
                            open={openDetailModal}
                            onClose={closeDetailModal}
                            TransitionComponent={Transition}
                        >
                            {isMobile && (
                                <Stack
                                    direction="row"
                                    justifyContent="space-between"
                                    sx={{
                                        width: 1,
                                        height: '46px',
                                        borderBottom: 'solid 1px',
                                        borderBottomColor: 'translucent.05'
                                    }}
                                >
                                    <Stack direction="row" alignItems="center" sx={{ flex: 'auto', height: '100%' }}>
                                        <Logo />
                                    </Stack>
                                    <IconButton color="inherit" edge="start" onClick={closeDialog}>
                                        <Icon icon="ic:outline-close" width="24" height="24" />
                                    </IconButton>
                                </Stack>
                            )}
                            <AppBar sx={{ position: 'relative' }}>
                                <Toolbar>
                                    <IconButton color="inherit" edge="start" onClick={closeDetailModal}>
                                        <Icon icon="iconamoon:arrow-left-6-circle-fill" width="24" height="24" />
                                    </IconButton>
                                    <Typography sx={{ flex: 1 }} variant="h6" component="div">
                                        {selected?.text}
                                    </Typography>
                                </Toolbar>
                            </AppBar>
                            <Box sx={{ width: '100%' }}>
                                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                    <Tabs
                                        value={getActiveIndex()}
                                        onChange={handleChange}
                                        sx={{
                                            "& .MuiTabs-flexContainer": {
                                                width: 1, justifyContent: 'center'
                                            },
                                            "& .MuiTab-root": {
                                                ...((selected?.children.length || 1) <= 3 && {
                                                    width: `${100 / (selected?.children.length || 1)}%`,
                                                    mr: "0px !important"
                                                }),
                                            },
                                            "& .MuiTabs-indicator": {
                                                backgroundColor: '#A71F67 !important'
                                            }
                                        }}
                                    >
                                        {selected?.children.map((child: IMenu, index: number) => (
                                            <Tab
                                                label={child.text}
                                                {...a11yProps(index)}
                                                key={'tab' + index}
                                                onClick={() => setActiveItem(child)}
                                            />
                                        ))}
                                    </Tabs>
                                </Box>
                            </Box>
                            {componentToRender}
                        </Dialog>
                    )}
                </Grid2>
            </DialogContent>
        </Dialog>
    );
};
export default AccountSettingDialog;
