// @mui
import {
    Box,
    Button,
    Collapse,
    Divider,
    FormControl,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    Stack,
    styled,
    Typography,
    useMediaQuery,
    useTheme
} from '@mui/material';
// datepicker mui
import dayjs from 'dayjs';
import { PickersDay } from '@mui/x-date-pickers';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
// hooks
import { useBoolean } from 'hooks/use-boolean';
// icon
import { Icon } from '@iconify/react';

const names = [
    'Oliver Hansen',
    'Van Henry',
    'April Tucker',
    'Ralph Hubbard',
    'Omar Alexander',
    'Carlos Abbott',
    'Miriam Wagner',
    'Bradley Wilkerson',
    'Virginia Andrews',
    'Kelly Snyder'
];

const StyledButton = styled(IconButton)(({ theme }) => ({
    borderRadius: theme.shape.borderRadius
}));
const StyledDay = styled(PickersDay)(({ theme }) => ({
    borderRadius: theme.shape.borderRadius,
    color: theme.palette.secondary.light,
    ...theme.applyStyles('light', {
        color: theme.palette.secondary.dark
    })
}));

const RenderFilter = () => {
    return (
        <>
            <Stack direction={{ md: 'row', xs: 'column' }} spacing={{ md: 0.5, xs: 1 }}>
                <Stack direction="row" sx={{ flex: 2 }} gap={0.5}>
                    <FormControl
                        variant="filled"
                        sx={{
                            width: 1,
                            '.MuiPopover-paper': { bgcolor: 'translucent.01' }
                        }}
                    >
                        <DatePicker
                            defaultValue={dayjs()}
                            label="Range"
                            slots={{
                                day: StyledDay,
                                openPickerButton: StyledButton
                            }}
                            slotProps={{
                                openPickerIcon: { fontSize: 'medium' },
                                openPickerButton: { color: 'default' },
                                textField: {
                                    variant: 'filled',
                                    focused: true,
                                    color: 'secondary'
                                }
                            }}
                        />
                    </FormControl>
                    <FormControl
                        variant="filled"
                        sx={{
                            width: 1,
                            '.MuiPopover-paper': { bgcolor: 'translucent.01' }
                        }}
                    >
                        <DatePicker
                            defaultValue={dayjs()}
                            slots={{
                                openPickerButton: StyledButton,
                                day: StyledDay
                            }}
                            slotProps={{
                                openPickerIcon: { fontSize: 'medium' },
                                openPickerButton: { color: 'default' },
                                textField: {
                                    variant: 'filled',
                                    focused: true,
                                    color: 'secondary'
                                }
                            }}
                        />
                    </FormControl>
                </Stack>
                <FormControl
                    variant="filled"
                    sx={{
                        width: 1,
                        flex: 1,
                        '.MuiPopover-paper': { bgcolor: 'translucent.01' }
                    }}
                >
                    <InputLabel>Sport</InputLabel>
                    <Select
                        sx={{
                            bgcolor: 'translucent.04',
                            '& .MuiTypography-root': {
                                color: 'white !important',
                                fontSize: 14
                            }
                        }}
                    >
                        {names.map((name) => (
                            <MenuItem
                                sx={{
                                    bgcolor: 'translucent.04',
                                    marginBottom: '1px !important',
                                    minHeight: '35px'
                                }}
                                key={name}
                                value={name}
                            >
                                <Typography fontSize={12} color="textSecondary">
                                    {name}
                                </Typography>
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>

                <FormControl
                    variant="filled"
                    sx={{ width: 1, flex: 1, '.MuiPopover-paper': { bgcolor: 'translucent.01' } }}
                >
                    <InputLabel>Competition</InputLabel>
                    <Select
                        sx={{
                            bgcolor: 'translucent.04',
                            '& .MuiTypography-root': {
                                color: 'white !important',
                                fontSize: 14
                            }
                        }}
                    >
                        {names.map((name) => (
                            <MenuItem
                                sx={{
                                    bgcolor: 'translucent.04',
                                    marginBottom: '1px !important',
                                    minHeight: '35px'
                                }}
                                key={name}
                                value={name}
                            >
                                <Typography fontSize={12} color="textSecondary">
                                    {name}
                                </Typography>
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Stack>
            <Stack direction="row" justifyContent="flex-end" sx={{ mt: 2 }}>
                <Button
                    variant="contained"
                    color="success"
                    sx={{ width: { md: 'auto', xs: 1 }, minWidth: 'calc(25% - 5px)' }}
                >
                    SHOW
                </Button>
            </Stack>
        </>
    );
};

const CollapseFilter = () => {
    const isOpened = useBoolean(false);
    return (
        <>
            <Stack direction="row" onClick={isOpened.onToggle} justifyContent={'space-between'}>
                <Stack direction="row" alignItems="center">
                    <Icon icon="typcn:filter" width="22" height="22" />
                    <Typography fontSize={12}>Filter</Typography>
                </Stack>
                <Box
                    component="i"
                    className="sp-s-l-h-arrow-icon-bc bc-i-small-arrow-down"
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        fontSize: 11,
                        borderRadius: 1,
                        lineHeight: 23,
                        transition: 'color 0.24s, background 0.24s',
                        color: isOpened.value ? 'common.white' : 'translucent.06',
                        ...(isOpened.value && {
                            transform: 'rotate(180deg)'
                        })
                    }}
                />
            </Stack>
            <Collapse in={isOpened.value}>
                <Stack mt={1} gap={1}>
                    <Divider sx={{ bgcolor: '#translucent.02' }} />
                    <RenderFilter />
                </Stack>
            </Collapse>
        </>
    );
};

const FinishFilter = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    return (
        <Box sx={{ mt: 1, mb: 2, px: 0.5 }}>
            <Stack
                sx={{
                    position: 'relative',
                    zIndex: 1,
                    p: 1,
                    borderRadius: 1,
                    justifyContent: 'space-between',
                    bgcolor: 'translucent.04'
                }}
            >
                {isMobile ? <CollapseFilter /> : <RenderFilter />}
            </Stack>
        </Box>
    );
};
export default FinishFilter;
