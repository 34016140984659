// @mui
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useMediaQuery, useTheme } from '@mui/material';

type Props = {
    oddName: string;
    odd: number;
    change: number;
};

const OddButton = ({ oddName, odd, change }: Props) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    return (
        <Button
            onClick={(e) => e.stopPropagation()}
            sx={{
                width: 1,
                minWidth: 0,
                minHeight: 34,
                py: 0.4,
                px: 1,
                borderRadius: 0,
                justifyContent: 'center',
                alignItems: 'center',
                flexWrap: 'nowrap',
                textAlign: 'center',
                height: 1,
                bgcolor: 'translucent.02',
                position: 'relative',
                transition: 'all 0.24s',
                '&:hover': {
                    bgcolor: 'primary.main',
                    span: { color: 'common.white' },
                    i: { borderColor: 'transparent transparent #fff transparent' }
                }
            }}
        >
            {!isMobile && (
                <Typography
                    component="span"
                    className="ellipsis"
                    sx={{
                        flex: 'auto',
                        textAlign: 'start',
                        mb: 0,
                        mr: 0.4,
                        fontSize: 12,
                        color: '#ffffffb3',
                        wordBreak: 'break-word',
                        transition: 'all 0.24s'
                    }}
                >
                    {oddName}
                </Typography>
            )}
            <Stack direction="row" alignItems="center">
                <Typography
                    component="span"
                    sx={{
                        flex: 'auto',
                        textAlign: 'start',
                        mr: 0.4,
                        fontSize: 12,
                        lineHeight: '14px',
                        color: 'gold',
                        transition: 'color 0.24s'
                    }}
                >
                    {odd}
                </Typography>
                {change !== 0 && (
                    <Box
                        component="i"
                        className={change < 0 ? 'market-arrow-up-down-bc is-down' : 'market-arrow-up-down-bc is-up'}
                        sx={{
                            right: 2,
                            width: 0,
                            height: 0,
                            display: 'block',
                            position: 'absolute',
                            borderStyle: 'solid',
                            transition: 'all 0.24s',
                            borderWidth: '0 0 8px 8px',
                            animation: 'odds-arrow-animation-change 7s forwards',

                            ...(change < 0
                                ? {
                                      bottom: 3,
                                      borderColor: 'transparent transparent red transparent'
                                  }
                                : {
                                      top: 3,
                                      transform: 'rotate(270deg)',
                                      borderColor: 'transparent transparent #16c72e transparent'
                                  })
                        }}
                    />
                )}
            </Stack>
        </Button>
    );
};

export default OddButton;
