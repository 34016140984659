import { useState } from 'react';
// @mui
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
// components
import AntSwitch from 'components/AntSwitch ';
import OddButton from 'components/Sports/OddButton';

type Props = {
    fullList: boolean;
    multiColumn: boolean;
    changeFullList: () => void;
    changeMultiColumn: () => void;
};

const EventList = ({ fullList, multiColumn, changeFullList, changeMultiColumn }: Props) => {
    const [marketAanchor, setMarketAnchor] = useState<HTMLElement | null>(null);

    const openMarketPopover = (event: React.MouseEvent<HTMLElement>) => {
        setMarketAnchor(event.currentTarget as HTMLElement);
    };

    const closeLangPopover = () => {
        setMarketAnchor(null);
    };

    return (
        <>
            <Box
                sx={{
                    width: fullList ? '50%' : '57%',
                    flexShrink: 0,
                    height: 1,
                    pr: 0.2,
                    willChange: 'scroll-position',
                    overflowX: 'hidden',
                    overflowY: 'scroll'
                }}
            >
                <Box
                    sx={
                        fullList
                            ? {
                                  position: 'absolute',
                                  top: 0,
                                  left: 0,
                                  width: 1,
                                  pr: 0.2,
                                  height: 1,
                                  bgcolor: 'background.default',
                                  zIndex: 5,
                                  willChange: 'scroll-position',
                                  overflowX: 'hidden',
                                  overflowY: 'scroll'
                              }
                            : {}
                    }
                >
                    <Stack
                        direction="row"
                        alignItems="center"
                        sx={{
                            width: 1,
                            height: 36,
                            mb: 0.1,
                            position: 'relative',
                            pl: 1,
                            pr: 0.5,
                            bgcolor: 'translucent.01',
                            borderRadius: 1
                        }}
                    >
                        <Box
                            component="i"
                            className="comp-fav-icon-bc bc-i-favorite "
                            sx={{
                                fontSize: 17,
                                color: 'translucent.08',
                                cursor: 'pointer'
                            }}
                        />
                        <Typography
                            sx={{
                                ml: 1,
                                flex: 'auto',
                                fontSize: 13,
                                lineHeight: '15px',
                                color: 'translucent.07',
                                userSelect: 'text',
                                overflow: 'hidden',
                                whiteSpace: 'nowrap',
                                textOverflow: 'ellipsis'
                            }}
                        >
                            UEFA Champions League
                        </Typography>

                        <Typography
                            component="span"
                            sx={{
                                mx: 0.5,
                                fontSize: 12,
                                lineHeight: '14px',
                                color: '#fffc'
                            }}
                        >
                            Multi-column
                        </Typography>

                        <AntSwitch checked={multiColumn} onChange={() => changeMultiColumn()} />

                        <IconButton onClick={changeFullList}>
                            <Box
                                component="i"
                                className={
                                    fullList
                                        ? 'multi-column-to-icon bc-i-block-to-left'
                                        : 'multi-column-to-icon bc-i-block-to-right'
                                }
                                sx={{
                                    fontSize: 16,
                                    color: 'translucent.08',
                                    cursor: 'pointer'
                                }}
                            />
                        </IconButton>
                    </Stack>

                    {multiColumn ? (
                        <Stack
                            direction="row"
                            alignItems="center"
                            sx={{
                                mt: 0.1,
                                py: 0.8,
                                pl: 1,
                                pr: 0.5,
                                height: 52,
                                bgcolor: 'translucent.04',
                                borderRadius: 1
                            }}
                        >
                            <Box sx={{ display: 'flex', flex: 'auto' }} />
                            <Stack justifyContent="space-between" sx={{ height: 1, width: 152, textAlign: 'center' }}>
                                <Typography sx={{ fontSize: 11, fontWeight: 500, textTransform: 'uppercase' }}>
                                    WINNER
                                </Typography>
                                <Stack direction="row" sx={{ inlineHeight: 12 }}>
                                    <Typography
                                        component="b"
                                        sx={{ fontSize: 11, fontWeight: 500, width: 1, textTransform: 'uppercase' }}
                                    >
                                        W1
                                    </Typography>
                                    <Typography
                                        component="b"
                                        sx={{ fontSize: 11, fontWeight: 500, width: 1, textTransform: 'uppercase' }}
                                    >
                                        X
                                    </Typography>
                                    <Typography
                                        component="b"
                                        sx={{ fontSize: 11, fontWeight: 500, width: 1, textTransform: 'uppercase' }}
                                    >
                                        W2
                                    </Typography>
                                </Stack>
                            </Stack>

                            <Box sx={{ display: 'flex', width: 49, ml: 0.8, mr: 0.3 }} />

                            <Stack justifyContent="space-between" sx={{ height: 1, width: 102, textAlign: 'center' }}>
                                <Typography sx={{ fontSize: 11, fontWeight: 500, textTransform: 'uppercase' }}>
                                    HANDICAP
                                </Typography>
                                <Stack direction="row" sx={{ inlineHeight: 12 }}>
                                    <Typography
                                        component="b"
                                        sx={{ fontSize: 11, fontWeight: 500, width: 1, textTransform: 'uppercase' }}
                                    >
                                        HOME
                                    </Typography>
                                    <Typography
                                        component="b"
                                        sx={{ fontSize: 11, fontWeight: 500, width: 1, textTransform: 'uppercase' }}
                                    >
                                        AWAY
                                    </Typography>
                                </Stack>
                            </Stack>

                            <Box sx={{ display: 'flex', width: 49, ml: 0.8, mr: 0.3 }} />

                            <Stack justifyContent="space-between" sx={{ height: 1, width: 102, textAlign: 'center' }}>
                                <Typography sx={{ fontSize: 11, fontWeight: 500, textTransform: 'uppercase' }}>
                                    TOTALS
                                </Typography>
                                <Stack direction="row" sx={{ inlineHeight: 12 }}>
                                    <Typography
                                        component="b"
                                        sx={{ fontSize: 11, fontWeight: 500, width: 1, textTransform: 'uppercase' }}
                                    >
                                        OVER
                                    </Typography>
                                    <Typography
                                        component="b"
                                        sx={{ fontSize: 11, fontWeight: 500, width: 1, textTransform: 'uppercase' }}
                                    >
                                        UNDER
                                    </Typography>
                                </Stack>
                            </Stack>

                            <Box sx={{ display: 'flex', width: 50, ml: 0.5, height: 1 }} />
                        </Stack>
                    ) : (
                        <Stack
                            direction="row"
                            alignItems="center"
                            sx={{ borderRadius: 1, bgcolor: 'translucent.04', height: 28 }}
                        >
                            <Box sx={{ flex: 'auto', height: 1 }}>
                                <Button
                                    onClick={openMarketPopover}
                                    sx={{
                                        height: 1,
                                        py: 0,
                                        px: 1.5,
                                        borderStartEndRadius: 0,
                                        borderEndEndRadius: 0,
                                        borderRight: '1px solid',
                                        borderColor: 'background.default'
                                    }}
                                    endIcon={
                                        <Box
                                            component="i"
                                            className="form-control-icon-bc bc-i-small-arrow-down"
                                            sx={{
                                                fontSize: '12px !important',
                                                color: '#fffc'
                                            }}
                                        />
                                    }
                                >
                                    Match Result
                                </Button>
                            </Box>
                        </Stack>
                    )}

                    <Box>
                        <Box sx={{ mb: 0.2, cursor: 'pointer' }}>
                            <Stack
                                direction="row"
                                alignItems="center"
                                sx={{
                                    mt: 0.4,
                                    pl: 1,
                                    pr: 0.2,
                                    bgcolor: 'translucent.05',
                                    borderRadius: 1,
                                    height: 36
                                }}
                            >
                                <Typography
                                    sx={{
                                        mr: 0.4,
                                        lineHeight: '15px',
                                        flex: 'auto',
                                        fontSize: 13,
                                        color: 'translucent.07',
                                        overflow: 'hidden',
                                        whiteSpace: 'nowrap',
                                        textOverflow: 'ellipsis'
                                    }}
                                >
                                    15.11.2024
                                </Typography>

                                <IconButton>
                                    <Box
                                        component="i"
                                        className="sgm-market-g-h-toggle-icon-bc bc-i-small-arrow-up"
                                        sx={{ fontSize: 11 }}
                                    />
                                </IconButton>
                            </Stack>
                        </Box>

                        {multiColumn ? (
                            <Stack
                                direction="row"
                                alignItems="center"
                                sx={{
                                    height: 54,
                                    width: 1,
                                    py: 1,
                                    pl: 1,
                                    pr: 0.5,
                                    borderRadius: 1,
                                    bgcolor: 'translucent.01',
                                    transition: 'background 0.24s',
                                    '&:hover': {
                                        bgcolor: 'translucent.04'
                                    }
                                }}
                            >
                                <Stack
                                    direction="row"
                                    alignItems="center"
                                    justifyContent="space-between"
                                    sx={{
                                        mr: 0.8,
                                        height: 1,
                                        flex: 'auto',
                                        cursor: 'pointer',
                                        overflow: 'hidden'
                                    }}
                                >
                                    <Stack
                                        justifyContent="space-between"
                                        sx={{ height: 1, mr: 1, overflow: 'hidden', userSelect: 'text' }}
                                    >
                                        <Typography
                                            className="ellipsis"
                                            sx={{ display: 'flex', fontSize: 12, fontWeight: 500 }}
                                        >
                                            Kazakhstan
                                        </Typography>
                                        <Typography
                                            className="ellipsis"
                                            sx={{ display: 'flex', fontSize: 12, fontWeight: 500 }}
                                        >
                                            Austria
                                        </Typography>
                                    </Stack>

                                    <Stack justifyContent="space-between" sx={{ height: 1 }}>
                                        <Typography
                                            sx={{
                                                color: '#ffffff80',
                                                fontSize: 11,
                                                whiteSpace: 'nowrap',
                                                textAlign: 'end'
                                            }}
                                        >
                                            00:00
                                        </Typography>

                                        <Stack direction="row" alignItems="center">
                                            <Box
                                                component="i"
                                                className="bc-i-favorite"
                                                sx={{ fontSize: 11, color: '#fffc' }}
                                            />
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    ml: 0.5
                                                }}
                                            >
                                                <Box
                                                    component="i"
                                                    className="c-info-icon-bc bc-i-bet-builder"
                                                    sx={{ fontSize: 11, color: '#eacb0b' }}
                                                />
                                            </Box>
                                        </Stack>
                                    </Stack>
                                </Stack>
                                <Stack direction="row" alignItems="center" spacing={0.3} sx={{ height: 1 }}>
                                    <Button
                                        color="primary"
                                        variant="contained"
                                        sx={{
                                            width: 50,
                                            height: 1,
                                            minWidth: 0,
                                            fontSize: 11,
                                            borderRadius: 1,
                                            color: '#ffd700',
                                            bgcolor: 'translucent.02',
                                            '&:hover': {
                                                color: 'common.white'
                                            }
                                        }}
                                    >
                                        11.00
                                    </Button>
                                    <Button
                                        color="primary"
                                        variant="contained"
                                        sx={{
                                            width: 50,
                                            height: 1,
                                            minWidth: 0,
                                            fontSize: 11,
                                            borderRadius: 1,
                                            color: '#ffd700',
                                            bgcolor: 'translucent.02',
                                            '&:hover': {
                                                color: 'common.white'
                                            }
                                        }}
                                    >
                                        11.00
                                    </Button>
                                    <Button
                                        color="primary"
                                        variant="contained"
                                        sx={{
                                            width: 50,
                                            height: 1,
                                            minWidth: 0,
                                            fontSize: 11,
                                            borderRadius: 1,
                                            color: '#ffd700',
                                            bgcolor: 'translucent.02',
                                            '&:hover': {
                                                color: 'common.white'
                                            }
                                        }}
                                    >
                                        11.00
                                    </Button>
                                </Stack>

                                {fullList && (
                                    <>
                                        <Box
                                            sx={{
                                                ml: 0.8,
                                                mr: 0.3,
                                                height: 1,
                                                width: 49,
                                                fontSize: 11,
                                                display: 'flex',
                                                borderRadius: 1,
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                border: '1px solid #ffffff4d'
                                            }}
                                        >
                                            +1.75-
                                        </Box>
                                        <Stack direction="row" alignItems="center" spacing={0.3} sx={{ height: 1 }}>
                                            <Button
                                                color="primary"
                                                variant="contained"
                                                sx={{
                                                    width: 50,
                                                    height: 1,
                                                    minWidth: 0,
                                                    fontSize: 11,
                                                    borderRadius: 1,
                                                    color: '#ffd700',
                                                    bgcolor: 'translucent.02',
                                                    '&:hover': {
                                                        color: 'common.white'
                                                    }
                                                }}
                                            >
                                                11.00
                                            </Button>
                                            <Button
                                                color="primary"
                                                variant="contained"
                                                sx={{
                                                    width: 50,
                                                    height: 1,
                                                    minWidth: 0,
                                                    fontSize: 11,
                                                    borderRadius: 1,
                                                    color: '#ffd700',
                                                    bgcolor: 'translucent.02',
                                                    '&:hover': {
                                                        color: 'common.white'
                                                    }
                                                }}
                                            >
                                                11.00
                                            </Button>
                                        </Stack>
                                    </>
                                )}

                                <Box
                                    sx={{
                                        ml: 0.8,
                                        mr: 0.3,
                                        height: 1,
                                        width: 49,
                                        fontSize: 11,
                                        display: 'flex',
                                        borderRadius: 1,
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        border: '1px solid #ffffff4d'
                                    }}
                                >
                                    +1.75-
                                </Box>
                                <Stack direction="row" alignItems="center" spacing={0.3} sx={{ height: 1 }}>
                                    <Button
                                        color="primary"
                                        variant="contained"
                                        sx={{
                                            width: 50,
                                            height: 1,
                                            minWidth: 0,
                                            fontSize: 11,
                                            borderRadius: 1,
                                            color: '#ffd700',
                                            bgcolor: 'translucent.02',
                                            '&:hover': {
                                                color: 'common.white'
                                            }
                                        }}
                                    >
                                        11.00
                                    </Button>
                                    <Button
                                        color="primary"
                                        variant="contained"
                                        sx={{
                                            width: 50,
                                            height: 1,
                                            minWidth: 0,
                                            fontSize: 11,
                                            borderRadius: 1,
                                            color: '#ffd700',
                                            bgcolor: 'translucent.02',
                                            '&:hover': {
                                                color: 'common.white'
                                            }
                                        }}
                                    >
                                        11.00
                                    </Button>
                                </Stack>

                                <Stack
                                    direction="row"
                                    alignItems="center"
                                    sx={{
                                        ml: 0.5,
                                        height: 1,
                                        width: 50,
                                        fontSize: 12,
                                        color: '#ffffff80',
                                        cursor: 'pointer',
                                        '&:hover': {
                                            color: '#fffc'
                                        }
                                    }}
                                >
                                    +371
                                    <Box
                                        component="i"
                                        className="bc-i-small-arrow-right"
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            width: 24,
                                            height: 24,
                                            color: '#fffc'
                                        }}
                                    />
                                </Stack>
                            </Stack>
                        ) : (
                            <Box sx={{ mt: 0.2 }}>
                                <Box sx={{ borderRadius: 1, overflow: 'hidden' }}>
                                    <Stack
                                        direction="row"
                                        alignItems="center"
                                        sx={{
                                            minHeight: 34,
                                            mb: 0.1,
                                            py: 0.5,
                                            px: 1,
                                            bgcolor: 'translucent.04',
                                            transition: 'background 0.24s',
                                            cursor: 'pointer',
                                            '&:hover': {
                                                bgcolor: 'translucent.09'
                                            }
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                maxWidth: '48%',
                                                overflow: 'hidden',
                                                whiteSpace: 'wrap',
                                                textOverflow: 'ellipsis',
                                                fontSize: 12,
                                                fontWeight: 500
                                            }}
                                        >
                                            Belgium
                                        </Typography>
                                        <Typography component="span" sx={{ color: '#ffffff80', mx: 0.8, fontSize: 11 }}>
                                            vs
                                        </Typography>
                                        <Typography
                                            sx={{
                                                maxWidth: '48%',
                                                overflow: 'hidden',
                                                whiteSpace: 'wrap',
                                                textOverflow: 'ellipsis',
                                                fontSize: 12,
                                                fontWeight: 500
                                            }}
                                        >
                                            Italy
                                        </Typography>
                                    </Stack>

                                    <Stack direction="row" alignItems="center" spacing={0.1}>
                                        <OddButton oddName="Belgium" odd={1.54} change={1} />
                                        <OddButton oddName="Draw" odd={1.53} change={0} />
                                        <OddButton oddName="Italy" odd={1.52} change={-1} />
                                    </Stack>

                                    <Stack
                                        direction="row"
                                        alignItems="center"
                                        sx={{
                                            height: 24,
                                            mt: 0.1,
                                            px: 0.5,
                                            cursor: 'pointer',
                                            bgcolor: 'translucent.01'
                                        }}
                                    >
                                        <Box
                                            component="i"
                                            className="bc-i-prematch"
                                            sx={{
                                                fontSize: 11,
                                                color: '#fffc'
                                            }}
                                        />
                                        <Typography
                                            className="ellipsis"
                                            sx={{ flex: 'auto', ml: 1, fontSize: 11, color: '#ffffff80' }}
                                        >
                                            04:45
                                        </Typography>
                                        <Box
                                            component="i"
                                            className="bc-i-favorite"
                                            sx={{ fontSize: 11, color: '#fffc' }}
                                        />
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                ml: 0.5
                                            }}
                                        >
                                            <Box
                                                component="i"
                                                className="c-info-icon-bc bc-i-bet-builder"
                                                sx={{ fontSize: 11, color: '#eacb0b' }}
                                            />
                                        </Box>
                                        <Typography
                                            className="ellipsis"
                                            sx={{ ml: 1, fontSize: 11, color: '#ffffff80' }}
                                        >
                                            +569
                                        </Typography>
                                    </Stack>
                                </Box>
                            </Box>
                        )}
                    </Box>
                </Box>
            </Box>

            {/* Market Select Popover */}
            <Popover
                id="market-popover"
                open={Boolean(marketAanchor)}
                anchorEl={marketAanchor}
                onClose={closeLangPopover}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center'
                }}
                sx={{
                    '.MuiPopover-paper': {
                        p: 0,
                        minWidth: 118,
                        maxHeight: 227,
                        bgcolor: 'background.default',
                        boxShadow: '0 0 10px 3px #0000004d, inset 0 0 0 300px #ffffff0d'
                    }
                }}
            >
                <Stack sx={{ p: 0.5 }} spacing={0.2}>
                    {new Array(15).fill(1).map((_, i) => (
                        <Stack
                            key={i}
                            onClick={() => closeLangPopover()}
                            direction="row"
                            alignItems="center"
                            sx={{
                                px: 1,
                                height: 30,
                                borderRadius: 1,
                                cursor: 'pointer',
                                bgcolor: 'translucent.01',
                                '&:hover': {
                                    bgcolor: 'translucent.02'
                                }
                            }}
                        >
                            <Typography sx={{ fontSize: 12, userSelect: 'none' }}>Match Result</Typography>
                        </Stack>
                    ))}
                </Stack>
            </Popover>
        </>
    );
};

export default EventList;
