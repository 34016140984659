import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { IconButton, Stack } from '@mui/material';
import { Icon } from '@iconify/react';

// ----------------------------------------------------------------------

export default function ScrollToTop() {
    const { pathname } = useLocation();

    const [isVisible, setIsVisible] = useState(false);

    const handleScroll = () => {
        // Get the current scroll position and the total height of the document
        const scrollY = window.scrollY;
        const innerHeight = window.innerHeight;
        // Check if user is at the bottom of the page
        // if (scrollY >= documentHeight) {
        if (scrollY >= innerHeight - 600) {
            setIsVisible(true);
        } else {
            setIsVisible(false);
        }
    };
    useEffect(() => {
        window.addEventListener('scroll', handleScroll);

        // Cleanup the event listener
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const Top = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };
    useEffect(() => {
        Top();
    }, [pathname]);

    if (isVisible) {
        return (
            <Stack
                direction="row"
                justifyContent="center"
                sx={{
                    position: 'fixed',
                    bottom: 80,
                    width: 1,
                    zIndex: 9999999
                }}
            >
                <IconButton
                    onClick={Top}
                    sx={{
                        bgcolor: 'translucent.04',
                        background: 'ffffff26',
                        p: 1.5,
                        '&:hover': {
                            backgroundColor: 'ffffff26'
                        },
                        '&:active': {
                            backgroundColor: 'ffffff26'
                        }
                    }}
                >
                    <Icon icon="mingcute:arrows-up-line" width="24" height="24" color="white" />
                </IconButton>
            </Stack>
        );
    }
    return null;
}
