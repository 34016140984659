import * as Yup from 'yup';
// import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { alpha } from '@mui/material/styles';
import { Stack, InputAdornment, IconButton, Alert, Grid2, Button } from '@mui/material';
import { useBoolean } from 'hooks/use-boolean';
import FormProvider from 'components/hook-form';
import { StyledRHFTextField } from 'components/hook-form/rhf-text-field';
import { Icon } from '@iconify/react';
// components
// hooks

// ----------------------------------------------------------------------

const PasswordChangeView = () => {
    const errorMsg = '';
    // const [errorMsg, setErrorMsg] = useState('');

    const password = useBoolean();

    const PasswordSchema = Yup.object().shape({
        currentPassword: Yup.string().required('Password is required'),
        newPassword: Yup.string().required('Password is required'),
        confirmPassword: Yup.string().required('Password is required')
    });

    const defaultValues = {
        currentPassword: '',
        newPassword: '',
        confirmPassword: ''
    };

    const methods = useForm({
        resolver: yupResolver(PasswordSchema),
        defaultValues
    });

    const {
        // reset,
        handleSubmit
        // formState: { isSubmitting },
    } = methods;

    const onSubmit = handleSubmit(async (data) => {
        // try {
        //   const response = await changePassword(data);
        //   if (response.status) enqueueSnackbar("Password changed successful", { variant: 'success' });
        // } catch (error) {
        //   console.error(error);
        //   reset();
        //   setErrorMsg(typeof error === 'string' ? error : error.message);
        // }
    });

    return (
        <Stack
            direction="column"
            gap={0.3}
            sx={{
                p: 1,
                width: 1,
                borderRadius: 2,
                bgcolor: (theme) => alpha(theme.palette.grey[500], 0.04)
            }}
        >
            <FormProvider methods={methods} onSubmit={onSubmit}>
                <Stack spacing={1}>
                    {!!errorMsg && <Alert severity="error">{errorMsg}</Alert>}

                    <StyledRHFTextField
                        variant="filled"
                        name="currentPassword"
                        label="Current Password"
                        type={password.value ? 'text' : 'password'}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton onClick={password.onToggle} edge="end">
                                        {/* <Iconify icon={password.value ? 'solar:eye-bold' : 'solar:eye-closed-bold'} /> */}
                                    </IconButton>
                                </InputAdornment>
                            )
                        }}
                    />

                    <StyledRHFTextField
                        name="newPassword"
                        variant="filled"
                        label="New Password"
                        type={password.value ? 'text' : 'password'}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton onClick={password.onToggle} edge="end">
                                        <Icon icon={password.value ? 'solar:eye-bold' : 'solar:eye-closed-bold'} />
                                    </IconButton>
                                </InputAdornment>
                            )
                        }}
                    />

                    <StyledRHFTextField
                        name="confirmPassword"
                        variant="filled"
                        label="Confirm Password"
                        type={password.value ? 'text' : 'password'}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton onClick={password.onToggle} edge="end">
                                        <Icon icon={password.value ? 'solar:eye-bold' : 'solar:eye-closed-bold'} />
                                    </IconButton>
                                </InputAdornment>
                            )
                        }}
                    />
                </Stack>
                <Grid2 container mt={2} justifyContent="flex-end">
                    <Button variant="contained" color="success" sx={{ minWidth: 'calc(25% - 5px)' }} type="submit">
                        CHANGE PASSWORD
                    </Button>
                </Grid2>
            </FormProvider>
        </Stack>
    );
};

export default PasswordChangeView;
