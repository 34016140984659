// @mui
import { Box, Button, Typography } from '@mui/material';
// types
import { IMarketNavigation } from 'types/sports';

type propsType = {
    market: IMarketNavigation;
    isActive: boolean;
    changeSMarket: (market: string) => void;
};
const MarketBtn = (props: propsType) => {
    const { market, isActive, changeSMarket } = props;
    return (
        <Box
            position={isActive ? 'sticky' : 'initial'}
            sx={{
                left: '0px',
                right: '0px',
                zIndex: isActive ? 30 : 1,
                bgcolor: 'background.default'
            }}
        >
            <Button
                onClick={() => changeSMarket(market.name)}
                sx={{
                    borderRadius: 0,
                    position: 'relative',
                    display: 'flex',
                    flexDirection: 'column',
                    minWidth: 'max-content',
                    height: '45px',
                    bgcolor: isActive ? 'translucent.05' : 'background.default',

                    '&:hover': {
                        bgcolor: isActive ? 'translucent.05' : 'background.default'
                    }
                }}
            >
                <Typography
                    fontSize={10}
                    textTransform="uppercase"
                    lineHeight="12px"
                    color={isActive ? 'white' : 'translucent.03'}
                >
                    {market.name}
                </Typography>
            </Button>
        </Box>
    );
};
export default MarketBtn;
