import { Suspense, useMemo } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
// @mui
import Box from '@mui/material/Box';
import { useMediaQuery, useTheme } from '@mui/material';
// components
import Header from 'components/Header';
import SportsFooter from 'components/Footer/SportsFooter';
import { LoadingScreen } from 'components/loading-screen';
import Footer from 'components/Footer';
import BottomBar from 'components/Footer/BottomBar';

const SportLayout = () => {
    const location = useLocation();

    const isResultPage = useMemo(() => {
        const param = location.pathname.split('/').pop();
        return param === 'results';
    }, [location.pathname]);

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <Suspense fallback={<LoadingScreen />}>
            <Box
                sx={{
                    width: 1,
                    display: 'grid',
                    paddingBottom: isMobile ? '30px' : 0,
                    minHeight: '100vh',
                    userSelect: 'none',
                    gridTemplateColumns: isResultPage || isMobile ? '100%' : '1fr 330px',
                    gridTemplateRows: isMobile ? 'auto 1fr auto auto' : 'auto 1fr auto',
                    gridTemplateAreas:
                        isResultPage || isMobile
                            ? `"header" "content" "footer" "betslip"`
                            : `"header header" "content betslip" "footer footer"`
                }}
            >
                <Header />
                <Outlet />
                {isMobile ? <Footer /> : <SportsFooter />}
            </Box>
            {isMobile && <BottomBar />}
        </Suspense>
    );
};

export default SportLayout;
