import { useFormContext, Controller } from 'react-hook-form';
// @mui
import TextField, { TextFieldProps } from '@mui/material/TextField';
import { styled } from '@mui/material';

// ----------------------------------------------------------------------

type Props = TextFieldProps & {
    name: string;
};

export const StyledRHFTextField = styled(RHFTextField)(() => ({
    borderRadius: '4px',
    '& label': {
        color: '#fff6',
        fontSize: '12px',
        top: '3px'
    },
    '$ div input': {
        background: '#ffffff26'
    },
    '& label.MuiInputLabel-root.Mui-error': {
        color: '#fff6'
    },
    '& .MuiFormHelperText-root.Mui-error': {
        borderRadius: '3px',
        margin: 0,
        marginTop: '3px',
        paddingLeft: '3px',
        color: 'white',
        background: '#ff344933',
        animation: 'form-control-message 0.24s ease'
    }
}));

export default function RHFTextField({ name, helperText, type, ...other }: Props) {
    const { control } = useFormContext();

    return (
        <Controller
            name={name}
            control={control}
            render={({ field, fieldState: { error } }) => (
                <TextField
                    {...field}
                    fullWidth
                    type={type}
                    value={type === 'number' && field.value === 0 ? '' : field.value}
                    onChange={(event) => {
                        if (type === 'number') {
                            field.onChange(Number(event.target.value));
                        } else {
                            field.onChange(event.target.value);
                        }
                    }}
                    error={!!error}
                    helperText={error ? error?.message : helperText}
                    {...other}
                />
            )}
        />
    );
}
