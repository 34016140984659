import { useState } from 'react';
// @mui
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Popover from '@mui/material/Popover';
import Collapse from '@mui/material/Collapse';
import Typography from '@mui/material/Typography';
import OddButton from 'components/Sports/OddButton';
import { useBoolean } from 'hooks/use-boolean';
// others
import { US } from 'country-flag-icons/react/3x2';
import { Icon } from '@iconify/react';
// hooks
import { useRouter } from 'routes/hook';

type MatchItemTypes = {};
const LiveMatchItem = (props: MatchItemTypes) => {
    const router = useRouter();
    return (
        <Stack
            width={1}
            gap={0.1}
            borderRadius={1}
            overflow="hidden"
            onClick={(e) => {
                router.push('usa/5555/premerleague/123123');
                e.stopPropagation();
            }}
        >
            <Stack direction="row" minHeight={52}>
                <Stack
                    flex={1}
                    sx={{
                        padding: '5px',
                        bgcolor: 'translucent.01',
                        gap: 0.3
                    }}
                >
                    <Stack direction="row" justifyContent="space-between">
                        <Typography fontSize={12}>Girona FC</Typography>
                        <Typography fontSize={12} color="gold">
                            1
                        </Typography>
                    </Stack>
                    <Stack direction="row" justifyContent="space-between">
                        <Typography fontSize={12}>Liverpool</Typography>
                        <Typography fontSize={12} color="gold">
                            1
                        </Typography>
                    </Stack>
                </Stack>
                <Stack flex={1} direction="row" gap={0.1}>
                    <OddButton oddName="Girona FC" odd={1.54} change={0} />
                    <OddButton oddName="Draw" odd={1.53} change={0} />
                    <OddButton oddName="Liverpool" odd={1.52} change={0} />
                </Stack>
            </Stack>
            <Stack
                flex={1}
                sx={{
                    padding: '5px',
                    bgcolor: 'translucent.01'
                }}
            >
                <Stack direction="row" alignItems="center" gap={0.2}>
                    <Icon icon="carbon:circle-filled" style={{ width: 15, height: 15 }} color={'#ff3449'} />
                    <Typography color="textSecondary" fontSize={11}>
                        2nd Half
                    </Typography>
                    <Typography color="textSecondary" fontSize={11}>
                        1:1,(0:0),(1:1)81`
                    </Typography>
                </Stack>
                <Typography color="textSecondary" fontSize={12}>
                    +25
                </Typography>
            </Stack>
        </Stack>
    );
};

const MobileSportItem = ({ index }: { index: number }) => {
    const isOpened = useBoolean();
    return (
        <>
            <Box
                onClick={isOpened.onToggle}
                sx={{ bgcolor: 'translucent.01', borderRadius: 1, transition: 'all 0.24s' }}
            >
                <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    sx={{
                        height: 37,
                        px: 1,
                        cursor: 'pointer',
                        borderRadius: 1,
                        bgcolor: 'background.default',
                        boxShadow: (theme) => `inset 0 0 0 19px ${theme.palette.translucent['01']}`,
                        transition: 'background  0.24s',
                        // -------------------------------
                        position: 'sticky',
                        top: 0,
                        zIndex: 1
                    }}
                >
                    <US title="United States" style={{ width: 25, height: 15, marginRight: 10 }} />
                    <Box
                        sx={{
                            display: 'flex',
                            flex: 'auto',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            height: 1
                        }}
                    >
                        <Typography
                            sx={{
                                fontSize: 12,
                                mr: 1,
                                textTransform: 'capitalize',
                                whiteSpace: 'nowrap',
                                textOverflow: 'ellipsis'
                            }}
                        >
                            USA
                        </Typography>
                        <Typography
                            sx={{
                                fontSize: 13,
                                mr: 1,
                                textTransform: 'capitalize',
                                whiteSpace: 'nowrap',
                                textOverflow: 'ellipsis'
                            }}
                        >
                            Premier League 2
                        </Typography>
                    </Box>
                    <Box
                        component="i"
                        className="sp-s-l-h-arrow-icon-bc bc-i-small-arrow-down"
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: 24,
                            height: 24,
                            ml: 1,
                            fontSize: 11,
                            borderRadius: 1,
                            lineHeight: 23,
                            transition: 'color 0.24s, background 0.24s',
                            color: isOpened.value ? 'common.white' : 'translucent.06',
                            ...(isOpened.value && {
                                transform: 'rotate(180deg)'
                            })
                        }}
                    />
                </Stack>
                <Collapse in={isOpened.value}>
                    <Stack gap={0.5} p={0.5}>
                        {new Array(2).fill(1).map((item, i) => (
                            <LiveMatchItem key={i} />
                        ))}
                    </Stack>
                </Collapse>
            </Box>
        </>
    );
};

const MobileLiveList = () => {
    const [marketAanchor, setMarketAnchor] = useState<HTMLElement | null>(null);
    const openMarketPopover = (event: React.MouseEvent<HTMLElement>) => {
        setMarketAnchor(event.currentTarget as HTMLElement);
    };
    const closeMarketPopover = () => {
        setMarketAnchor(null);
    };

    return (
        <Box
            sx={{
                flex: 'auto',
                position: 'relative',
                mt: 0.5
            }}
        >
            <Stack direction="row" px={1} py={0.2} gap={0.5} width={1} bgcolor="translucent.01" alignItems="center">
                <Button
                    onClick={openMarketPopover}
                    size="small"
                    endIcon={<Icon icon="lsicon:down-outline" width="24" height="24" />}
                    sx={{
                        px: 1,
                        justifyContent: 'space-between',
                        flex: 1,
                        bgcolor: 'translucent.05',
                        textTransform: 'uppercase',
                        height: 25
                    }}
                >
                    winner
                </Button>
                <Stack
                    position="relative"
                    direction="row"
                    justifyContent="space-around"
                    flex={1}
                    sx={{
                        boxShadow: '-1px 0 0 0 #000c24'
                    }}
                >
                    <Typography fontSize={11}>W1</Typography>
                    <Typography fontSize={11}>X</Typography>
                    <Typography fontSize={11}>W2</Typography>
                </Stack>
            </Stack>
            <Stack
                sx={{
                    marginTop: 1,
                    width: 1,
                    minHeight: '100%',
                    pr: 0.2
                }}
                spacing={0.2}
            >
                {new Array(17).fill(1).map((_, i) => (
                    <MobileSportItem key={i} index={i} />
                ))}
            </Stack>

            {/* league popover */}
            <Popover
                id="market-popover"
                open={Boolean(marketAanchor)}
                anchorEl={marketAanchor}
                onClose={closeMarketPopover}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center'
                }}
                sx={{
                    '.MuiPopover-paper': {
                        p: 0,
                        minWidth: '90%',
                        marginLeft: '-7px',
                        maxHeight: 230,
                        bgcolor: 'background.default',
                        boxShadow: '0 0 0px 0px #0000004d, inset 0 0 0 100px #ffffff0d'
                    }
                }}
            >
                <Stack sx={{ p: 0.5 }} spacing={0.2}>
                    {new Array(3).fill(1).map((_, i) => (
                        <Stack
                            key={i}
                            onClick={() => closeMarketPopover()}
                            direction="row"
                            alignItems="center"
                            sx={{
                                px: 1,
                                height: 30,
                                borderRadius: 1,
                                cursor: 'pointer',
                                bgcolor: 'translucent.01',
                                '&:hover': {
                                    bgcolor: 'translucent.02'
                                }
                            }}
                        >
                            <Typography sx={{ fontSize: 12, userSelect: 'none' }}>Winner</Typography>
                        </Stack>
                    ))}
                </Stack>
            </Popover>
        </Box>
    );
};

export default MobileLiveList;
