import { Link, useLocation } from 'react-router-dom';
import { useMemo, useRef, useState } from 'react';
// mui
import {
    Box,
    Button,
    Collapse,
    ListItemIcon,
    ListItemText,
    MenuItem,
    MenuList,
    Popover,
    styled,
    SxProps,
    Theme
} from '@mui/material';
// icon

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
    minHeight: '34px',
    height: '34px',
    background: 'linear-gradient(to right,rgba(255, 255, 255,.15) 0%,rgba(255, 255, 255,.05) 100%)',
    minWidth: 220,
    borderLeft: '2.5px solid',
    borderLeftColor: 'transparent',
    borderRadius: 3,
    marginBottom: '2px',
    '&:hover': {
        borderLeftColor: theme.palette.primary.main
    },
    '&:not(:last-of-type)': {
        marginBottom: '2px !important'
    }
}));

interface children {
    title: string;
    path: string;
    icon?: JSX.Element;
}
interface navDataType {
    title: string;
    icon?: JSX.Element;
    path: string;
    children?: children[];
}

interface propTypes {
    index: number;
    navData: navDataType;
    sx?: SxProps<Theme>;
}

const HeaderBtn = ({ index, navData, sx }: propTypes) => {
    const pathname = useLocation();
    const navRef = useRef(null);
    const [open, setOpen] = useState<boolean>(false);

    const isActive = useMemo(() => {
        if (pathname.pathname.split('/').length <= 2) {
            return false;
        }
        if (pathname.pathname.split('/')[2] === navData.path.split('/')[2]) {
            return true;
        } else {
            return false;
        }
    }, [pathname, navData.path]);

    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    return (
        <Box>
            <Link to={navData.path}>
                <Button
                    variant="contained"
                    color="primary"
                    startIcon={navData.icon}
                    onMouseEnter={handleOpen}
                    onMouseLeave={handleClose}
                    ref={navRef}
                    sx={{
                        ...sx,
                        px: 2,
                        py: 0,
                        overflow: 'hidden',
                        minWidth: 0,
                        fontSize: 15,
                        height: '100%',
                        borderRadius: 0,
                        borderTop: '2px solid',
                        letterSpacing: '0.1px',
                        bgcolor: open ? 'background.default' : isActive ? 'primary.main' : 'transparent',
                        borderTopColor: open ? 'primary.main' : 'transparent',
                        '&:hover': {
                            bgcolor: 'background.default',
                            boxShadow: 'none',
                            borderTopColor: 'primary.main'
                        },
                        ...(index === 0 && { borderTopLeftRadius: 4, borderBottomLeftRadius: 4 })
                    }}
                >
                    {navData.title}
                </Button>
            </Link>
            {navData.children && (
                <Popover
                    open={open}
                    anchorEl={navRef.current}
                    aria-hidden={false}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                    transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                    slotProps={{
                        paper: {
                            onMouseEnter: handleOpen,
                            onMouseLeave: handleClose,
                            elevation: 0,
                            sx: {
                                boxShadow: 'none',
                                bgcolor: 'background.default',
                                marginLeft: -0.5,
                                borderTopLeftRadius: 0,
                                borderTopRightRadius: 0,
                                padding: '8px',
                                ...(open && {
                                    pointerEvents: 'auto'
                                })
                            }
                        }
                    }}
                    sx={{
                        pointerEvents: 'none'
                    }}
                >
                    <Collapse in={open} orientation="vertical">
                        <MenuList sx={{ gap: 0 }}>
                            {navData.children.map((item, key) => (
                                <Link aria-atomic to={item.path} key={key} replace>
                                    <StyledMenuItem
                                        onClick={handleClose}
                                        sx={{ color: 'common.white' }}
                                        LinkComponent={Link}
                                    >
                                        {item.icon && (
                                            <ListItemIcon sx={{ minWidth: '15px !important', marginRight: '10px' }}>
                                                {item.icon}
                                            </ListItemIcon>
                                        )}
                                        <ListItemText>{item.title}</ListItemText>
                                    </StyledMenuItem>
                                </Link>
                            ))}
                        </MenuList>
                    </Collapse>
                </Popover>
            )}
        </Box>
    );
};
export default HeaderBtn;
