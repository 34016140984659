import { useState } from 'react';
// @mui
import { Box, Button, Collapse, IconButton, Popover, Stack, Typography, useTheme } from '@mui/material';
// others
import { Icon } from '@iconify/react';
// config
import { marketNavigations } from 'config';
// components
import MarketBtn from 'components/Sports/MarketBtn';
import OddButton from 'components/Sports/OddButton';
// hooks
import { useRouter } from 'routes/hook';
import { useBoolean } from 'hooks/use-boolean';

type MatchGroupTypes = {
    index: number;
};
type MatchItemTypes = {};
const MatchItem = (props: MatchItemTypes) => {
    const theme = useTheme();
    const router = useRouter();
    return (
        <Stack
            width={1}
            gap={0.1}
            borderRadius={1}
            overflow="hidden"
            onClick={(e) => {
                router.push('123123123');
                e.stopPropagation();
            }}
        >
            <Stack direction="row" minHeight={52}>
                <Stack
                    flex={1}
                    sx={{
                        padding: '5px',
                        bgcolor: 'translucent.01',
                        gap: 0.3
                    }}
                >
                    <Typography fontSize={12}>Girona FC</Typography>
                    <Typography fontSize={12}>Liverpool</Typography>
                </Stack>
                <Stack flex={1} direction="row" gap={0.1}>
                    <OddButton oddName="Girona FC" odd={1.54} change={0} />
                    <OddButton oddName="Draw" odd={1.53} change={0} />
                    <OddButton oddName="Liverpool" odd={1.52} change={0} />
                </Stack>
            </Stack>
            <Stack
                flex={1}
                sx={{
                    padding: '5px',
                    bgcolor: 'translucent.01'
                }}
            >
                <Stack direction="row" alignItems="center" gap={0.2}>
                    <Icon
                        icon="material-symbols:timer-outline"
                        style={{ width: 15, height: 15 }}
                        color={theme.palette.grey[500]}
                    />
                    <Typography color="textSecondary" fontSize={11}>
                        02:45
                    </Typography>
                </Stack>
                <Typography color="textSecondary" fontSize={12}>
                    +319
                </Typography>
            </Stack>
        </Stack>
    );
};
const MatchGroupByDate = (props: MatchGroupTypes) => {
    const isOpened = useBoolean(true);
    return (
        <Box
            sx={{
                bgcolor: 'translucent.01',
                transition: 'all 0.24s'
            }}
            onClick={isOpened.onToggle}
        >
            <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                sx={{
                    height: 33,
                    px: 1,
                    cursor: 'pointer',
                    borderRadius: 1,
                    bgcolor: 'background.default',
                    boxShadow: (theme) => `inset 0 0 0 18px ${theme.palette.translucent['01']}`,
                    transition: 'background  0.24s',
                    // -------------------------------
                    position: 'sticky',
                    top: 0,
                    zIndex: 1
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flex: '1',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        height: 1
                    }}
                >
                    <Typography
                        color="textSecondary"
                        sx={{
                            fontSize: 11,
                            mr: 1,
                            textTransform: 'capitalize',
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis'
                        }}
                    >
                        11.12.2024
                    </Typography>
                </Box>
                <Stack
                    position="relative"
                    direction="row"
                    justifyContent="space-around"
                    flex={1}
                    sx={{
                        boxShadow: '-1px 0 0 0 #000c24'
                    }}
                >
                    <Typography fontSize={11}>W1</Typography>
                    <Typography fontSize={11}>X</Typography>
                    <Stack direction="row" alignItems="center" justifyContent="space-between">
                        <Typography fontSize={11}>W2</Typography>
                        <Box
                            component="i"
                            className="sp-s-l-h-arrow-icon-bc bc-i-small-arrow-down"
                            sx={{
                                position: 'absolute',
                                right: 0,
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                fontSize: 11,
                                borderRadius: 1,
                                lineHeight: 23,
                                transition: 'color 0.24s, background 0.24s',
                                color: isOpened.value ? 'common.white' : 'translucent.06',
                                ...(isOpened.value && {
                                    transform: 'rotate(180deg)'
                                })
                            }}
                        />
                    </Stack>
                </Stack>
            </Stack>
            <Collapse in={isOpened.value}>
                <Stack
                    px={0.5}
                    sx={{
                        gap: 1,
                        pb: 0.5
                    }}
                >
                    {new Array(4).fill(1).map((_, index) => (
                        <MatchItem key={index} />
                    ))}
                </Stack>
            </Collapse>
        </Box>
    );
};

const SportMatches = () => {
    const router = useRouter();
    const [leagueAanchor, setLeagueAnchor] = useState<HTMLElement | null>(null);
    const [selectedMarket, setSelectedMarket] = useState<string>('');
    const changeSMarket = (market: string) => {
        setSelectedMarket(market);
    };
    const openLeaguePopover = (event: React.MouseEvent<HTMLElement>) => {
        setLeagueAnchor(event.currentTarget as HTMLElement);
    };
    const closeLeaguePopover = () => {
        setLeagueAnchor(null);
    };

    return (
        <Box sx={{ gridArea: 'unset', width: '100%' }}>
            <Button
                size="small"
                onClick={() => router.back()}
                fullWidth
                sx={{
                    justifyContent: 'flex-start',
                    pl: 1,
                    background: 'transparent',
                    marginTop: -1,
                    height: 40
                }}
                startIcon={<Icon icon="icon-park-solid:left-c" style={{ width: 22, height: 22, color: '#fffc' }} />}
            >
                <Typography textTransform="uppercase" fontSize={14}>
                    Volleyball/turkey
                </Typography>
            </Button>
            <Stack direction="row" px={1} gap={0.5} width={1} bgcolor="translucent.01" alignItems="center">
                <Button
                    onClick={openLeaguePopover}
                    size="small"
                    endIcon={<Icon icon="lsicon:down-outline" width="24" height="24" />}
                    sx={{
                        px: 1,
                        justifyContent: 'space-between',
                        flex: 1,
                        bgcolor: 'translucent.05'
                    }}
                >
                    UEFA Champions League. Outright
                </Button>

                <IconButton
                    sx={{
                        px: 0
                    }}
                >
                    <Icon icon="mdi:star-outline" width="22" height="22" />
                </IconButton>
            </Stack>
            <Box
                width={1}
                sx={{
                    mt: 0.1,
                    overflowX: 'auto',
                    overflowY: 'hidden',
                    '& ::-webkit-scrollbar': {
                        display: 'none'
                    },
                    '& ::-moz-scrollbar': {
                        display: 'none'
                    },
                    scrollbarWidth: 'none',
                    msOverflowStyle: 'none'
                }}
            >
                <Stack direction="row" sx={{ width: '100%', overflow: 'auto', position: 'relative' }}>
                    {marketNavigations.map((item, i) => (
                        <MarketBtn
                            changeSMarket={changeSMarket}
                            isActive={selectedMarket === item.name}
                            market={item}
                            key={i}
                        />
                    ))}
                </Stack>
            </Box>
            <Stack sx={{ width: 1, minHeight: '100%', mt: 0.1 }} spacing={0.1}>
                {new Array(5).fill(1).map((_, i) => (
                    <MatchGroupByDate key={i} index={i} />
                ))}
            </Stack>
            {/* league popover */}
            <Popover
                id="market-popover"
                open={Boolean(leagueAanchor)}
                anchorEl={leagueAanchor}
                onClose={closeLeaguePopover}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center'
                }}
                sx={{
                    '.MuiPopover-paper': {
                        p: 0,
                        minWidth: '90%',
                        marginLeft: '-7px',
                        maxHeight: 230,
                        bgcolor: 'background.default',
                        boxShadow: '0 0 0px 0px #0000004d, inset 0 0 0 100px #ffffff0d'
                    }
                }}
            >
                <Stack sx={{ p: 0.5 }} spacing={0.2}>
                    {new Array(3).fill(1).map((_, i) => (
                        <Stack
                            key={i}
                            onClick={() => closeLeaguePopover()}
                            direction="row"
                            alignItems="center"
                            sx={{
                                px: 1,
                                height: 30,
                                borderRadius: 1,
                                cursor: 'pointer',
                                bgcolor: 'translucent.01',
                                '&:hover': {
                                    bgcolor: 'translucent.02'
                                }
                            }}
                        >
                            <Typography sx={{ fontSize: 12, userSelect: 'none' }}>
                                UEFA Champions League. Outright
                            </Typography>
                        </Stack>
                    ))}
                </Stack>
            </Popover>
        </Box>
    );
};
export default SportMatches;
