import { useState } from 'react';
// @mui
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';

const SlipBoard = () => {
    const [marketAanchor, setMarketAnchor] = useState<HTMLElement | null>(null);

    const openMarketPopover = (event: React.MouseEvent<HTMLElement>) => {
        setMarketAnchor(event.currentTarget as HTMLElement);
    };

    const closeLangPopover = () => {
        setMarketAnchor(null);
    };

    return (
        <>
            <Box
                sx={{
                    pr: 1,
                    gridArea: 'betslip'
                }}
            >
                <Box
                    sx={{
                        pb: 1,
                        height: 1,
                        opacity: 1,
                        position: 'relative',
                        transform: 'translateY(0px)'
                    }}
                >
                    <Stack
                        sx={{
                            top: 0,
                            pr: 0.2,
                            left: 0,
                            width: 1,
                            maxHeight: 1,
                            minHeight: 1,
                            display: 'flex',
                            overflowX: 'hidden',
                            overflowY: 'scroll',
                            position: 'absolute',
                            flexDirection: 'column',
                            willChange: 'scroll-position'
                        }}
                    >
                        <Box>
                            <Box
                                sx={{
                                    mb: 0.2,
                                    minHeight: 34,
                                    overflow: 'hidden',
                                    transition: 'min-height 0.24s'
                                }}
                            >
                                <Stack
                                    direction="row"
                                    alignItems="center"
                                    sx={{
                                        px: 1,
                                        mb: 0.2,
                                        height: 34,
                                        bgcolor: 'translucent.05',
                                        borderRadius: 1
                                    }}
                                >
                                    <Box
                                        component="i"
                                        className="game-details-c-live-i-item-bc bc-i-field-soccer active"
                                        sx={{
                                            color: 'common.white',
                                            width: 'auto',
                                            height: 20,
                                            fontSize: 14,
                                            px: 0.4,
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            bgcolor: '#0006',
                                            cursor: 'pointer',
                                            borderRadius: 1
                                        }}
                                    />
                                </Stack>
                                <Box>
                                    <Box
                                        sx={{
                                            width: 332,
                                            height: 198,
                                            mb: '-10px',
                                            transform: 'scale(.946)',
                                            transformOrigin: '0 0'
                                        }}
                                    >
                                        <iframe
                                            title="event"
                                            src="https://widget-iframe.wadua.io"
                                            style={{ width: '100%', height: '100%', border: 0 }}
                                        />
                                    </Box>
                                </Box>
                            </Box>
                        </Box>

                        <Box
                            sx={{
                                display: 'grid',
                                gridTemplateRows: 'auto auto 9fr auto',
                                gridTemplateColumns: 'auto',
                                gridTemplateAreas: `
                                "betslip-header"
                                "betslip-type"
                                "betslip-items"
                                "betslip-footer"`,
                                rowGap: '2px'
                            }}
                        >
                            <Box
                                sx={{
                                    width: 1,
                                    gridArea: 'betslip-header'
                                }}
                            >
                                <Stack
                                    alignItems="center"
                                    justifyContent="center"
                                    sx={{
                                        mb: 0.3,
                                        height: 34,
                                        width: 1,
                                        position: 'relative',
                                        bgcolor: '#ffffff33',
                                        borderTopLeftRadius: 4,
                                        borderTopRightRadius: 4,
                                        boxShadow: (theme) => `0 2px 0 0 ${theme.palette.translucent['04']}`,
                                        '&::before': {
                                            left: 0,
                                            width: 1,
                                            content: `""`,
                                            display: 'block',
                                            height: '1px',
                                            bgcolor: 'primary.main',
                                            position: 'absolute',
                                            bottom: 0,
                                            transition: 'width .24s, left .24s'
                                        }
                                    }}
                                >
                                    <Typography sx={{ fontSize: 12 }}>Betslip</Typography>
                                </Stack>

                                <Stack
                                    direction="row"
                                    alignItems="center"
                                    justifyContent="space-between"
                                    sx={{
                                        bgcolor: 'translucent.05',
                                        borderEndStartRadius: 4,
                                        borderEndEndRadius: 4
                                    }}
                                >
                                    <Button
                                        size="small"
                                        sx={{
                                            px: 1,
                                            height: 36,
                                            color: 'translucent.08'
                                        }}
                                        startIcon={
                                            <Box
                                                component="i"
                                                className="select-icon-bc bc-i-settings"
                                                sx={{
                                                    color: 'translucent.08',
                                                    fontSize: 16
                                                }}
                                            />
                                        }
                                        endIcon={
                                            <Box
                                                component="i"
                                                className="form-control-icon-bc bc-i-small-arrow-down"
                                                sx={{
                                                    fontSize: '10px !important',
                                                    color: 'translucent.08'
                                                }}
                                            />
                                        }
                                    >
                                        Accept Any Odds
                                    </Button>
                                </Stack>
                            </Box>

                            <Stack
                                direction="row"
                                alignItems="center"
                                sx={{
                                    my: 0.2,
                                    gridArea: 'betslip-type',
                                    height: 34,
                                    pr: 1,
                                    bgcolor: 'translucent.04',
                                    borderRadius: 1
                                }}
                            >
                                <Button
                                    size="small"
                                    onClick={openMarketPopover}
                                    sx={{
                                        bgcolor: 'transparent',
                                        py: 0,
                                        px: 1,
                                        height: 1,
                                        borderStartEndRadius: 0,
                                        borderEndEndRadius: 0
                                    }}
                                    endIcon={
                                        <Box
                                            component="i"
                                            className="form-control-icon-bc bc-i-small-arrow-down"
                                            sx={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                fontSize: '10px !important',
                                                width: 20,
                                                height: 20,
                                                textAlign: 'center'
                                            }}
                                        />
                                    }
                                >
                                    Single
                                </Button>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flex: 'auto',
                                        alignItems: 'center',
                                        justifyContent: 'flex-end'
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            textDecoration: 'underline',
                                            cursor: 'pointer',
                                            fontSize: 12,
                                            color: 'translucent.03'
                                        }}
                                    >
                                        Remove All
                                    </Typography>
                                </Box>
                            </Stack>

                            <Box
                                sx={{
                                    height: 1,
                                    width: 1,
                                    position: 'relative',
                                    gridArea: 'betslip-items'
                                }}
                            >
                                {new Array(3).fill(3).map((_, i) => (
                                    <Box
                                        key={i}
                                        sx={{
                                            mb: 0.2,
                                            cursor: 'pointer'
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                width: 1,
                                                height: 1,
                                                position: 'relative',
                                                zIndex: 5
                                            }}
                                        >
                                            <Box
                                                sx={{
                                                    bgcolor: 'translucent.01',
                                                    borderRadius: 1
                                                }}
                                            >
                                                <Box sx={{ width: 1, px: 0.8, py: 0.6 }}>
                                                    <Box
                                                        sx={{
                                                            mr: 0.6,
                                                            width: 10,
                                                            ml: 'auto',
                                                            height: 16,
                                                            display: 'flex',
                                                            justifyContent: 'flex-end'
                                                        }}
                                                    >
                                                        <Box
                                                            component="i"
                                                            className="bs-bet-i-h-c-icon-bc remove bc-i-close-remove"
                                                            sx={{
                                                                color: 'translucent.06',
                                                                fontSize: 10
                                                            }}
                                                        />
                                                    </Box>
                                                    <Box sx={{ mt: 0.3 }}>
                                                        <Stack
                                                            direction="row"
                                                            alignItems="center"
                                                            sx={{ minHeight: 14, lineHeight: '16px' }}
                                                        >
                                                            <Typography
                                                                sx={{
                                                                    fontSize: 12,
                                                                    color: '#fffffff2',
                                                                    display: 'flex',
                                                                    alignItems: 'center',
                                                                    flex: 'auto'
                                                                }}
                                                            >
                                                                Under (2)
                                                            </Typography>
                                                            <Typography
                                                                component="span"
                                                                sx={{
                                                                    color: '#ffd700',
                                                                    display: 'inline-block',
                                                                    verticalAlign: 'top',
                                                                    position: 'relative',
                                                                    fontSize: 12,
                                                                    ml: 1.2
                                                                }}
                                                            >
                                                                1.44
                                                            </Typography>
                                                        </Stack>
                                                        <Stack
                                                            direction="row"
                                                            alignItems="center"
                                                            sx={{ minHeight: 14, lineHeight: '16px', mt: 0.3 }}
                                                        >
                                                            <Typography
                                                                sx={{
                                                                    fontSize: 12,
                                                                    color: 'translucent.06',
                                                                    display: 'flex',
                                                                    alignItems: 'center',
                                                                    flex: 'auto',
                                                                    opacity: 0.5
                                                                }}
                                                            >
                                                                Weston Super Mare AFC Total Goals (Bands)
                                                            </Typography>
                                                            <Typography
                                                                component="span"
                                                                sx={{
                                                                    color: '#ff3449',
                                                                    display: 'inline-block',
                                                                    verticalAlign: 'top',
                                                                    position: 'relative',
                                                                    fontSize: 12,
                                                                    ml: 1.2,
                                                                    textDecoration: 'line-through'
                                                                }}
                                                            >
                                                                1.44
                                                            </Typography>
                                                        </Stack>
                                                    </Box>
                                                    <Stack
                                                        direction="row"
                                                        alignItems="center"
                                                        sx={{ minHeight: 14, lineHeight: '16px' }}
                                                    >
                                                        <Stack sx={{ flex: 'auto' }}>
                                                            <Typography
                                                                sx={{
                                                                    fontSize: 11,
                                                                    color: '#ffffffb3'
                                                                }}
                                                            >
                                                                Hemel Hempstead Town - Weston Super Mare AFC
                                                            </Typography>
                                                            <Typography
                                                                sx={{
                                                                    fontSize: 12,
                                                                    color: '#fff6'
                                                                }}
                                                            >
                                                                12.11.2024, 04:45
                                                            </Typography>
                                                        </Stack>
                                                        <Box sx={{ width: 16, height: '100%' }}>
                                                            <Box
                                                                sx={{
                                                                    ml: 'auto',
                                                                    mb: 0.6,
                                                                    display: 'flex',
                                                                    width: 10,
                                                                    height: 16,
                                                                    position: 'relative',
                                                                    lineHeight: 1,
                                                                    fontSize: 10,
                                                                    justifyContent: 'flex-end',
                                                                    alignItems: 'center'
                                                                }}
                                                            >
                                                                <Box
                                                                    component="i"
                                                                    className="bs-bet-i-h-c-icon-bc attention bc-i-attention"
                                                                    sx={{
                                                                        color: '#ff3449',
                                                                        fontSize: 10
                                                                    }}
                                                                />
                                                            </Box>
                                                        </Box>
                                                    </Stack>
                                                </Box>
                                            </Box>
                                        </Box>
                                    </Box>
                                ))}

                                <Box sx={{ width: 1 }}>
                                    <Box sx={{ py: 1, px: 0.7 }}>
                                        <Stack
                                            direction="row"
                                            alignItems="center"
                                            justifyContent="space-between"
                                            sx={{ mt: 0.6, maxHeight: 150 }}
                                        >
                                            <Typography sx={{ fontSize: 12, color: '#fffffff2' }}>Odds</Typography>
                                            <Typography sx={{ fontSize: 12, color: '#ffd700' }}>1200</Typography>
                                        </Stack>

                                        <Stack sx={{ mt: 1 }}>
                                            <Box
                                                sx={{
                                                    width: 1,
                                                    height: 36,
                                                    cursor: 'pointer',
                                                    position: 'relative',
                                                    transition: 'max-width 0.24s',
                                                    bgcolor: 'translucent.01',
                                                    borderRadius: 1,
                                                    border: '1px solid',
                                                    borderColor: 'translucent.02',
                                                    px: 1
                                                }}
                                            >
                                                <Box
                                                    component="input"
                                                    placeholder="Enter stake"
                                                    sx={{
                                                        px: 0.8,
                                                        width: 1,
                                                        height: 1,
                                                        fontSize: 14,
                                                        outline: 'none',
                                                        border: 0,
                                                        bgcolor: 'transparent',
                                                        color: 'common.white'
                                                    }}
                                                />
                                            </Box>
                                        </Stack>

                                        <Stack
                                            direction="row"
                                            alignItems="center"
                                            justifyContent="space-between"
                                            sx={{ mt: 0.6, maxHeight: 150 }}
                                        >
                                            <Typography sx={{ fontSize: 12, color: '#fffffff2' }}>
                                                Possible win:
                                            </Typography>
                                            <Typography sx={{ fontSize: 12, color: '#109121' }}>
                                                7,158,357.88 MAD
                                            </Typography>
                                        </Stack>
                                    </Box>
                                </Box>
                            </Box>

                            <Box
                                sx={{
                                    gridArea: 'betslip-footer',
                                    mb: 1.6
                                }}
                            >
                                <Box
                                    sx={{
                                        mt: 0.2,
                                        gridTemplateColumns: '1fr',
                                        bgcolor: 'translucent.05',
                                        borderRadius: 1,
                                        p: 1,
                                        width: 1,
                                        gridGap: '2px'
                                    }}
                                >
                                    <Box
                                        sx={{
                                            display: 'grid',
                                            gridTemplateColumns: 'repeat(3, 1fr) 36px',
                                            gridColumnGap: '2px',
                                            gridRowGap: '2px'
                                        }}
                                    >
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            sx={{ minWidth: 0, bgcolor: 'translucent.01' }}
                                        >
                                            50
                                        </Button>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            sx={{ minWidth: 0, bgcolor: 'translucent.01' }}
                                        >
                                            100
                                        </Button>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            sx={{ minWidth: 0, bgcolor: 'translucent.01' }}
                                        >
                                            500
                                        </Button>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            sx={{ minWidth: 0, bgcolor: 'translucent.01' }}
                                        >
                                            <Box
                                                component="i"
                                                className="bs-number-button-icon-bc bc-i-edit"
                                                sx={{ color: '#fffc', fontSize: 18 }}
                                            />
                                        </Button>
                                    </Box>
                                </Box>
                                <Box
                                    sx={{
                                        mt: 0.2,
                                        bgcolor: 'translucent.05',
                                        borderRadius: 1,
                                        p: 1,
                                        width: 1,
                                        gridGap: '2px'
                                    }}
                                >
                                    <Box
                                        sx={{
                                            display: 'grid'
                                        }}
                                    >
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            sx={{ minWidth: 0, bgcolor: 'translucent.01', textTransform: 'uppercase' }}
                                        >
                                            Accept changes and place bet
                                        </Button>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </Stack>
                </Box>
            </Box>
            {/* Market Select Popover */}
            <Popover
                id="market-popover"
                open={Boolean(marketAanchor)}
                anchorEl={marketAanchor}
                onClose={closeLangPopover}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center'
                }}
                sx={{
                    '.MuiPopover-paper': {
                        p: 0,
                        minWidth: 87,
                        bgcolor: 'background.default',
                        boxShadow: '0 0 10px 3px #0000004d, inset 0 0 0 300px #ffffff0d'
                    }
                }}
            >
                <Stack sx={{ p: 0.5 }} spacing={0.2}>
                    {new Array(2).fill(1).map((_, i) => (
                        <Stack
                            key={i}
                            onClick={() => closeLangPopover()}
                            direction="row"
                            alignItems="center"
                            sx={{
                                px: 1,
                                height: 30,
                                borderRadius: 1,
                                cursor: 'pointer',
                                bgcolor: 'translucent.01',
                                '&:hover': {
                                    bgcolor: 'translucent.02'
                                }
                            }}
                        >
                            <Typography sx={{ fontSize: 12, userSelect: 'none' }}>Single</Typography>
                        </Stack>
                    ))}
                </Stack>
            </Popover>
        </>
    );
};

export default SlipBoard;
