import { IMarketNavigation, ISportNavigation } from 'types/sports';

const NODE_ENV = process.env.NODE_ENV;

export const HOST_API_KEY =
    NODE_ENV === 'production' ? process.env.REACT_APP_HOST_API : process.env.REACT_APP_LOCAL_HOST_API;

export const PATH_AFTER_LOGIN = '/auth/login';

export const STYLE = {
    layoutWidth: 1670,
    headerHeight: 56,
    m_headerHeight: 49,
    subHeaderHeight: 36,
    borderRadius: '4px',
    backgroundDuration: '.24s'
};

export const COLOR_LIST = [
    '#109121',
    '#fca601',
    '#a19e3e',
    '#dac179',
    '#50a2c6',
    '#839d24',
    '#65b5c2',
    '#868686',
    '#42777f',
    '#e7685c',
    '#119a18',
    '#ac9afd',
    '#5caaa7',
    '#0e7acc',
    '#1bab7d',
    '#1bab7d',
    '#edbd6f'
];

export const sportNavigations: ISportNavigation[] = [
    {
        name: 'Football',
        icon: '',
        matchCount: 0,
        path: 'soccer',
        color: '#109121'
    },
    {
        name: 'Basketball',
        icon: '',
        matchCount: 0,
        path: 'basketball',
        color: '#fca601'
    },
    {
        name: 'Tennis',
        icon: '',
        matchCount: 0,
        path: 'tennis',
        color: '#a19e3e'
    },
    {
        name: 'Volleyball',
        icon: '',
        matchCount: 0,
        path: 'volleyball',
        color: '#dac179'
    },
    {
        name: 'Ice Hockey',
        icon: '',
        matchCount: 0,
        path: 'ice_hockey',
        color: '#50a2c6'
    },
    {
        name: 'Table Tennis',
        icon: '',
        matchCount: 0,
        path: 'table_tennis',
        color: '#839d24'
    },
    {
        name: 'Futsal',
        icon: '',
        matchCount: 0,
        path: 'futsal',
        color: '#65b5c2'
    },
    {
        name: 'MMA',
        icon: '',
        matchCount: 0,
        path: 'mma',
        color: '#868686'
    },
    {
        name: 'Handball',
        icon: '',
        matchCount: 0,
        path: 'handball',
        color: '#42777f'
    },
    {
        name: 'American Football',
        icon: '',
        matchCount: 0,
        path: 'american_football',
        color: '#e7685c'
    },
    {
        name: 'Auto Racing',
        icon: '',
        matchCount: 0,
        path: 'auto_racing',
        color: '#119a18'
    },
    {
        name: 'Alpine Skiing',
        icon: '',
        matchCount: 0,
        path: 'alpine_skiing',
        color: '#ac9afd'
    },
    {
        name: 'Baseball',
        icon: '',
        matchCount: 0,
        path: 'baseball',
        color: '#5caaa7'
    },
    {
        name: 'Biathlon',
        icon: '',
        matchCount: 0,
        path: 'biathlon',
        color: '#0e7acc'
    },
    {
        name: 'Boxing',
        icon: '',
        matchCount: 0,
        path: 'boxing',
        color: '#1bab7d'
    },
    {
        name: 'Bandy',
        icon: '',
        matchCount: 0,
        path: 'bandy',
        color: '#1bab7d'
    },
    {
        name: 'Cricket',
        icon: '',
        matchCount: 0,
        path: 'cricket',
        color: '#edbd6f'
    }
];
export const marketNavigations: IMarketNavigation[] = [
    {
        name: 'match winner',
        id: ''
    },
    {
        name: 'points handicap',
        id: ''
    },
    {
        name: 'total points',
        id: ''
    },
    {
        name: 'total sets',
        id: ''
    },
    {
        name: '1 set winner',
        id: ''
    },
    {
        name: '1 set points handicap',
        id: ''
    },
    {
        name: '1 set points handicap asian',
        id: ''
    },
    {
        name: '1 set total points',
        id: ''
    },
    {
        name: '1 set total points asian',
        id: ''
    }
];

// ======= Result Tab in Sports Page =======
export const RESULT_PAGES = ['live', 'finished'];
