import { Suspense } from 'react';
import Wrapper from './Wrapper';
// components
import Header from 'components/Header';
import Footer from 'components/Footer';
import { LoadingScreen } from 'components/loading-screen';
// mui
import { Box, Container, useMediaQuery, useTheme } from '@mui/material';
// config
import { STYLE } from 'config';
import BottomBar from 'components/Footer/BottomBar';

const MainLayout = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    return (
        <Suspense fallback={<LoadingScreen />}>
            <Header />
            <Wrapper />
            <Box
                component="main"
                sx={{
                    paddingBottom: isMobile ? '30px' : 0
                }}
            >
                <Container sx={{ maxWidth: `${STYLE.layoutWidth}px !important`, px: 0 }}>
                    <Footer />
                </Container>
            </Box>
            {isMobile && <BottomBar />}
        </Suspense>
    );
};

export default MainLayout;
