// @mui
import { Box, Button, Typography } from '@mui/material';
// others
import { Icon } from '@iconify/react';
// hooks
import { useRouter } from 'routes/hook';
// close components
import MobileEventDetail from './MobileEventDetail';

type propTypes = {
    isLive: boolean;
};
const Odds = (props: propTypes) => {
    const { isLive } = props;
    const router = useRouter();

    return (
        <Box sx={{ gridArea: 'unset', width: '100%' }}>
            <Button
                size="small"
                onClick={() => router.back()}
                fullWidth
                sx={{
                    justifyContent: 'flex-start',
                    pl: 1,
                    background: 'transparent',
                    marginTop: -1,
                    height: 40
                }}
                startIcon={<Icon icon="icon-park-solid:left-c" style={{ width: 22, height: 22, color: '#fffc' }} />}
            >
                <Typography textTransform="uppercase" fontSize={14}>
                    uefa champions league
                </Typography>
            </Button>
            <MobileEventDetail isFull isLive={isLive} />
        </Box>
    );
};
export default Odds;
