// ----------------------------------------------------------------------

const ROOTS = {
    AUTH: '/auth',
    HOME: '/',
    SPORTS: {
        live: '/sports/live',
        pre_match: '/sports/pre-match'
    }
};

// ----------------------------------------------------------------------

export const paths = {
    // AUTH
    auth: {
        login: `${ROOTS.AUTH}/login`,
        opt: `${ROOTS.AUTH}/opt`,
        register: `${ROOTS.AUTH}/register`
    },
    sports: {
        live: {
            event_view: `${ROOTS.SPORTS.live}/event-view`,
            live_calendar: `${ROOTS.SPORTS.live}/live-calendar`,
            results: `${ROOTS.SPORTS.live}/results`
        },
        pre: {
            event_view: `${ROOTS.SPORTS.pre_match}/event-view`,
            live_calendar: `${ROOTS.SPORTS.pre_match}/live-calendar`,
            results: `${ROOTS.SPORTS.pre_match}/results`
        }
    },
    // HOME
    home: {
        root: ROOTS.HOME
        // report: {
        //     list: `${ROOTS.DASHBOARD}/report/list`,
        //     new: `${ROOTS.DASHBOARD}/report/new`,
        //     edit: (id: string) => `${ROOTS.DASHBOARD}/report/${id}/edit`,
        //     details: (id: string) => `${ROOTS.DASHBOARD}/report/${id}`
        // }
    }
};
