import * as Yup from 'yup';
// import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { Stack, Divider, Grid2, Button, useTheme, useMediaQuery } from '@mui/material';
import FormProvider from 'components/hook-form';
import { StyledRHFTextField } from 'components/hook-form/rhf-text-field';
// components

const PersonalDetailView = () => {
    // const [errorMsg, setErrorMsg] = useState('');

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const UserDataSchema = Yup.object().shape({
        email: Yup.string().required('Email is required').email('Email must be a valid email address'),
        name: Yup.string().required('Name required')
    });

    const defaultValues = {
        name: '',
        email: ''
    };

    const methods = useForm({
        resolver: yupResolver(UserDataSchema),
        defaultValues
    });

    const {
        // reset,
        handleSubmit
        // formState: { isSubmitting },
    } = methods;

    const onSubmit = handleSubmit(async (data) => {
        // try {
        //   console.log(data);
        //   // const response = await register(data);
        //   // console.log(response, "response")
        //   // if (response?.data) enqueueSnackbar("Registration successful", { variant: 'success' });
        // } catch (error) {
        //   console.error(error);
        //   reset();
        //   setErrorMsg(typeof error === 'string' ? error : error.message);
        // }
    });

    return (
        <Stack
            direction="column"
            gap={0.5}
            sx={{
                py: 2,
                px: 1,
                width: 1,
                borderRadius: 2
            }}
        >
            <FormProvider methods={methods} onSubmit={onSubmit}>
                <Grid2 container spacing={0.5}>
                    <Grid2 size={12}>
                        <StyledRHFTextField variant="filled" name="username" label="Username" disabled />
                    </Grid2>
                    <Grid2 size={{ xs: 12, md: 6 }}>
                        <StyledRHFTextField variant="filled" name="firstName" label="First name" disabled />
                    </Grid2>

                    <Grid2 size={{ xs: 12, md: 6 }}>
                        <StyledRHFTextField variant="filled" name="lastName" label="Last name" disabled />
                    </Grid2>
                    <Grid2 size={{ xs: 12, md: 6 }}>
                        <StyledRHFTextField variant="filled" name="birthday" label="Birth Date" disabled />
                    </Grid2>

                    <Grid2 size={{ xs: 12, md: 6 }}>
                        <StyledRHFTextField variant="filled" name="gender" label="Gender" disabled />
                    </Grid2>
                    <Grid2 size={{ xs: 12, md: 6 }}>
                        <StyledRHFTextField variant="filled" name="email" label="E-mail" disabled />
                    </Grid2>
                    <Grid2 size={{ xs: 12, md: 6 }}>
                        <StyledRHFTextField variant="filled" name="passport" label="Passport / ID" disabled />
                    </Grid2>
                    <Grid2 size={{ xs: 12, md: 6 }}>
                        <StyledRHFTextField variant="filled" name="country" label="Country" disabled />
                    </Grid2>
                    <Grid2 size={{ xs: 12, md: 6 }}>
                        <StyledRHFTextField variant="filled" name="city" label="City" disabled />
                    </Grid2>
                    <Grid2 size={{ xs: 12, md: 6 }}>
                        <StyledRHFTextField variant="filled" name="address" label="Address" disabled />
                    </Grid2>
                    <Grid2 size={{ xs: 12, md: 6 }}>
                        <StyledRHFTextField variant="filled" name="phoneNumber" label="Phone number" disabled />
                    </Grid2>
                    <Divider />
                    <Grid2 size={12}>
                        <StyledRHFTextField variant="filled" name="password" label="Current password" disabled />
                    </Grid2>
                </Grid2>
                <Grid2 container mt={2} justifyContent="flex-end">
                    <Button variant="contained" color="success" sx={{ minWidth: 'calc(25% - 5px)', width: isMobile ? '100%' : 'auto' }} type="submit">
                        SAVE CHANGES
                    </Button>
                </Grid2>
            </FormProvider>
        </Stack>
    );
};

export default PersonalDetailView;
