import { alpha } from '@mui/material/styles';

// ----------------------------------------------------------------------

export type ColorSchema = 'primary' | 'secondary' | 'info' | 'success' | 'warning' | 'error';

declare module '@mui/material/styles/createPalette' {
    interface TypeBackground {
        neutral: string;
    }
    interface TypeBackground {
        neutral: string;
    }
    interface SimplePaletteColorOptions {
        lighter: string;
        darker: string;
    }
    interface PaletteColor {
        lighter: string;
        darker: string;
    }

    interface Palette {
        translucent: {
            '01': string;
            '02': string;
            '03': string;
            '04': string;
            '05': string;
            '06': string;
            '07': string;
            '08': string;
            '09': string;
        };
    }

    interface PaletteOptions {
        translucent?: {
            main?: string;
            light?: string;
            dark?: string;
            contrastText?: string;
        };
    }
}

// SETUP COLORS

const GREY = {
    0: '#FFFFFF',
    100: '#F9FAFB',
    200: '#F4F6F8',
    300: '#DFE3E8',
    400: '#C4CDD5',
    500: '#919EAB',
    600: '#637381',
    700: '#454F5B',
    800: '#212B36',
    900: '#161C24'
};

const PRIMARY = {
    lighter: '#C75488',
    light: '#B73377',
    main: '#A71F67',
    dark: '#911A58',
    darker: '#7A1548',
    contrastText: '#FFFFFF'
};

const SECONDARY = {
    lighter: '#EFD6FF',
    light: '#C684FF',
    main: '#8E33FF',
    dark: '#5119B7',
    darker: '#27097A',
    contrastText: '#FFFFFF'
};

const INFO = {
    lighter: '#CAFDF5',
    light: '#61F3F3',
    main: '#00B8D9',
    dark: '#006C9C',
    darker: '#003768',
    contrastText: '#FFFFFF'
};

const SUCCESS = {
    lighter: '#15be2b',
    light: '#15be2b',
    main: '#109121',
    dark: '#118D57',
    darker: '#065E49',
    contrastText: '#ffffff'
};

const WARNING = {
    lighter: '#FFF5CC',
    light: '#FFD666',
    main: '#FFAB00',
    dark: '#B76E00',
    darker: '#7A4100',
    contrastText: GREY[800]
};

const ERROR = {
    lighter: '#FFE9D5',
    light: '#FFAC82',
    main: '#FF5630',
    dark: '#B71D18',
    darker: '#7A0916',
    contrastText: '#FFFFFF'
};

const COMMON = {
    common: {
        black: '#000000',
        white: '#FFFFFF'
    },
    translucent: {
        '01': '#000c24b3',
        '02': '#fff3',
        '03': '#ffffff80',
        '04': '#ffffff26',
        '05': '#ffffff0d',
        '06': '#ffffffb3',
        '07': '#ffffffe6',
        '08': '##000c24',
        '09': '#ffffff40'
    },
    primary: PRIMARY,
    secondary: SECONDARY,
    info: INFO,
    success: SUCCESS,
    warning: WARNING,
    error: ERROR,
    grey: GREY,
    divider: alpha(GREY[500], 0.2),
    action: {
        hover: alpha(GREY[500], 0.08),
        selected: alpha(GREY[500], 0.16),
        disabled: alpha(GREY[500], 0.8),
        disabledBackground: alpha(GREY[500], 0.24),
        focus: alpha(GREY[500], 0.24),
        hoverOpacity: 0.08,
        disabledOpacity: 0.48
    }
};

export function palette(mode: 'light' | 'dark') {
    const light = {
        ...COMMON,
        mode: 'light',
        text: {
            primary: GREY[800],
            secondary: GREY[600],
            disabled: GREY[500]
        },
        background: {
            paper: '#FFFFFF',
            default: '#FFFFFF',
            neutral: GREY[200]
        },
        action: {
            ...COMMON.action,
            active: GREY[600]
        }
    };

    const dark = {
        ...COMMON,
        mode: 'dark',
        text: {
            primary: '#FFFFFF',
            secondary: GREY[500],
            disabled: GREY[600]
        },
        background: {
            bottomBar: '#000c24e6',
            paper: '#000c24cc',
            default: '#000c24',
            neutral: '#ffffff26'
        },
        action: {
            ...COMMON.action,
            active: GREY[500]
        }
    };

    return mode === 'light' ? light : dark;
}
