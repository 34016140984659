import { lazy } from 'react';
// auth
import {
    // AuthGuard,
    GuestGuard
} from 'utils/guard';
// layouts
import MainLayout from 'layouts/HomeLayout';
// components

// ----------------------------------------------------------------------
// OVERVIEW
const HomePage = lazy(() => import('pages/home'));
// ----------------------------------------------------------------------

export const mainRoutes = [
    {
        path: '/',

        element: (
            // <AuthGuard>
            <MainLayout />
            // </AuthGuard>
        ),

        children: [
            {
                path: '/',
                element: <HomePage />
            },
            {
                index: true,
                path: 'home',
                element: <HomePage />
            }
        ]
    },
    {
        path: '/auth',
        element: (
            <GuestGuard>
                <MainLayout />
            </GuestGuard>
        )
    }
];
