// @mui
import { Box, Button, Typography, Stack, TextField, useMediaQuery, useTheme } from '@mui/material';
import { alpha } from '@mui/material/styles';

// others
import { Icon } from '@iconify/react';
// types
import { childrenProp, IMenu, IMenuType } from 'types/sports/profile';
// ----------------------------------------------------------------------

const menuList: IMenuType[] = [
    {

        icon: <Box component='i' className='user-nav-icon bc-i-user' />,
        // icon: <Icon icon='solar:user-circle-bold' width={24} height={24} />,
        text: 'My Profile',
        value: 'profile',
        children: [
            { icon: <Icon icon='solar:user-circle-bold' width={24} height={24} />, text: 'Personal Detail', value: 'personal-detail' },
            { icon: <Icon icon='mdi:password' width={24} height={24} />, text: 'Change Password', value: 'change-password' }
        ]
    },
    {
        icon: <Icon icon='ri:money-dollar-circle-line' width={24} height={24} />,
        text: 'Balance Management',
        value: 'balance-management',
        children: [
            { icon: <Icon icon='solar:dollar-bold' width={24} height={24} />, text: 'Deposit', value: 'deposit' },
            { icon: <Icon icon='hugeicons:bitcoin-withdraw' width={24} height={24} />, text: 'Withdraw', value: 'withdraw' },
            { icon: <Icon icon='material-symbols:history' width={24} height={24} />, text: 'Transaction History', value: 'transaction-history' },
            { icon: <Icon icon='mdi:information' width={24} height={24} />, text: 'Info', value: 'info' }
        ]
    },
    {
        icon: <Icon icon='ri:history-fill' width={24} height={24} />,
        text: 'Bet History',
        value: 'bet-history',
        children: [
            { icon: <Icon icon='ic:sharp-menu-book' width={24} height={24} />, text: 'All', value: 'all_bets' },
            { icon: <Icon icon='solar:clipboard-list-bold' width={24} height={24} />, text: 'Open bets', value: 'open_bets' },
            { icon: <Icon icon='solar:user-circle-bold' width={24} height={24} />, text: 'Won', value: 'won_bets' },
            // { icon: <Icon icon='icon-park-outline:check-one' width={24} height={24} />, text: 'Won', value: 'won_bets' },
            { icon: <Icon icon='zondicons:close-outline' width={24} height={24} />, text: 'Lost', value: 'lost_bets' }
        ]
    },
    {
        icon: <Icon icon='mingcute:gift-line' width={24} height={24} />,
        text: 'Bonuses',
        value: 'bonus',
        children: [
            { icon: <Icon icon='f7:sportscourt-fill' width={24} height={24} />, text: 'Sport Bonus', value: 'sport-bonus' },
            { icon: <Icon icon='mdi:casino' width={24} height={24} />, text: 'Casino Bonus', value: 'casino-bonus' },
            { icon: <Icon icon='ri:history-fill' width={24} height={24} />, text: 'Bonus History', value: 'bonus-history' }
        ]
    },
    {
        icon: <Icon icon='lets-icons:message-duotone' width={24} height={24} />,
        text: 'Messages',
        value: 'message',
        children: [
            { icon: <Icon icon='material-symbols:inbox' width={24} height={24} />, text: 'Inbox', value: 'message_inbox' },
            { icon: <Icon icon='material-symbols:send' width={24} height={24} />, text: 'Sent', value: 'message_sent' },
            { icon: <Icon icon='icons8:plus' width={24} height={24} />, text: 'New', value: 'message_new' }
        ]
    }
];

interface MenuProps {
    menu: IMenu;
    onChange?: (panel: childrenProp) => void;
}

const MenuNavigation = ({ menu, onChange }: MenuProps) => (
    <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{
            height: 42,
            pl: 1,
            pr: 1.5,
            cursor: 'pointer',
            borderRadius: 1,
            bgcolor: alpha('#FFF', 0.15)
        }}
        onClick={() => (onChange ? onChange(menu) : '')}
    >
        <Stack direction="row" width={1}>
            {menu.icon}
            <Typography
                sx={{
                    fontSize: 15,
                    mx: 1,
                    width: 1,
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis'
                }}
            >
                {menu.text}
            </Typography>
        </Stack>
        <Box
            component="i"
            className="sp-s-l-h-arrow-icon-bc bc-i-small-arrow-right"
            sx={{
                fontSize: 12,
                color: alpha('#FFF', 0.5)
            }}
        />
    </Stack>
);

interface DetailProps {
    data: IMenuType;
    onClose: () => void;
    onChange?: (panel: childrenProp) => void;
}

const DetailNavigation = ({ data, onClose, onChange }: DetailProps) => (
    <Stack gap={0.1}>
        <Stack
            direction="row"
            alignItems="center"
            sx={{
                bgcolor: alpha('#FFF', 0.05),
                height: 42,
                pl: 1,
                pr: 1.5,
                borderRadius: 0.4,
                cursor: 'pointer'
            }}
            onClick={onClose}
        >
            <Box
                component="i"
                className="sp-s-l-h-arrow-icon-bc bc-i-small-arrow-right"
                sx={{
                    fontSize: 12,
                    transform: 'rotate(180deg)',
                    color: alpha('#FFF', 0.5)
                }}
            />
            <Typography fontSize={15} mx={1} color={alpha('#FFF', 0.9)} width={1}>
                {data.text}
            </Typography>
        </Stack>
        {/* <Collapse in={true} orientation='horizontal' timeout={4000}> */}
        <Stack gap={0.1}>
            {data.children.length &&
                data.children.map((menu, index: number) => (
                    <MenuNavigation key={index} menu={menu} onChange={onChange} />
                ))}
        </Stack>
        {/* </Collapse> */}
    </Stack>
);

const MobileMenu = ({
    selected,
    setActiveItem,
    setSelected
}: {
    selected: IMenuType | null;
    setActiveItem: (value: childrenProp) => void;
    setSelected: (value: IMenuType | null) => void;
}) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const handleChange = (panel: childrenProp) => {
        setActiveItem(panel);
    };

    return (
        <Stack direction="column" gap={1}>
            <Stack direction="column" gap={isMobile ? 0.1 : 1}>
                {selected ? (
                    <DetailNavigation data={selected} onClose={() => setSelected(null)} onChange={handleChange} />
                ) : (
                    menuList.map((menu, i) => (
                        <Box
                            key={'menu-' + i}
                            onClick={() => setSelected(menu)}
                            sx={{ borderRadius: 1, transition: 'all 0.24s' }}
                        >
                            <MenuNavigation menu={menu} />
                        </Box>
                    ))
                )}

                <Stack direction="row" py={1.5} justifyContent="center">
                    <Box
                        display="flex"
                        gap={1}
                        sx={{
                            bgcolor: 'translucent.01',
                            py: 1,
                            borderRadius: 1,
                            width: 1
                        }}
                    >
                        <TextField
                            variant="filled"
                            label="Promo Code"
                            sx={{
                                width: '100%',
                                marginInlineEnd: '10px'
                            }}
                        />
                        <Button
                            variant="contained"
                            color="success"
                            disabled
                            sx={{
                                width: 'auto'
                            }}
                        >
                            APPLY
                        </Button>
                    </Box>
                </Stack>
                <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    sx={{
                        height: 50,
                        px: 1,
                        cursor: 'pointer',
                        borderRadius: 1,
                        bgcolor: 'translucent.05',
                        '&:hover': {
                            bgcolor: 'translucent.01'
                        }
                    }}
                >
                    <Stack direction="row" gap={1} alignItems="center">
                        <Icon icon="ic:twotone-logout" width="24" height="24" />
                        <Typography
                            sx={{
                                fontSize: 13,
                                mr: 1,
                                textTransform: 'uppercase',
                                whiteSpace: 'nowrap',
                                textOverflow: 'ellipsis'
                            }}
                        >
                            Log out
                        </Typography>
                    </Stack>
                </Stack>
            </Stack>
        </Stack>
    );
};

export default MobileMenu;
