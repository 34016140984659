// @mui
import Box, { BoxProps } from '@mui/material/Box';
import LoadingImg from 'assets/image/loading.png';

// ----------------------------------------------------------------------

export default function LoadingScreen({ sx, ...other }: BoxProps) {
    return (
        <Box
            sx={{
                px: 5,
                width: 1,
                flexGrow: 1,
                minHeight: 1,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                ...sx
            }}
            {...other}
        >
            <Box
                component="img"
                src={LoadingImg}
                sx={{
                    width: 80,
                    height: 80,
                    maxWidth: 100,
                    maxHeight: 100
                }}
            />
        </Box>
    );
}
