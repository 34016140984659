// layouts
import SportLayout from 'layouts/SportLayout';
// ----------------------------------------------------------------------
// OVERVIEW
import EventView from 'pages/sports/Event';
import LiveCalendar from 'pages/sports/LiveCalendar';
import Result from 'pages/sports/Result';
// ----------------------------------------------------------------------

export const desktopSportsRoutes = [
    {
        path: '/sports/live',
        element: <SportLayout />,
        children: [
            {
                path: 'event-view',
                element: <EventView isLive />
            },
            {
                path: 'live-calendar',
                element: <LiveCalendar />
            },
            {
                path: 'results',
                element: <Result />
            }
        ]
    },
    {
        path: '/sports/pre-match',
        element: <SportLayout />,
        children: [
            {
                path: 'event-view',
                element: <EventView isLive={false} />
            },
            {
                path: 'live-calendar',
                element: <LiveCalendar />
            },
            {
                path: 'results',
                element: <Result />
            }
        ]
    }
];
