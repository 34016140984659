import { useMemo } from 'react';
// @mui
import { Box, Button, Typography } from '@mui/material';
// types
import { ISportNavigation } from 'types/sports';
// components
import { Icon } from '@iconify/react';
// hook
import { useParams, useRouter } from 'routes/hook';
// routes
import { paths } from 'routes/paths';

type propsType = {
    sport: ISportNavigation;
    isLive: boolean;
};
type paramType = {
    sport?: string;
};
const SportBtn = (props: propsType) => {
    const { sport, isLive } = props;
    const router = useRouter();
    const params: paramType = useParams();
    const isActive = useMemo(() => {
        if (params.sport && params.sport === sport.path) {
            return true;
        } else {
            return false;
        }
    }, [params, sport]);
    return (
        <Box position={isActive ? 'sticky' : 'initial'} sx={{ left: '0px', right: '0px', zIndex: isActive ? 2 : 1 }}>
            <Button
                sx={{
                    position: 'relative',
                    display: 'flex',
                    flexDirection: 'column',
                    minWidth: '80px',
                    height: '60px',
                    gap: 0.5,
                    bgcolor: isActive ? sport.color : 'translucent.01',

                    '&:hover': {
                        bgcolor: isActive ? sport.color : 'translucent.01'
                    }
                }}
                onClick={() => {
                    if (isLive) {
                        router.push(`${paths.sports.live.event_view}/${sport.path}`);
                    } else {
                        router.push(`${paths.sports.pre.event_view}/${sport.path}`);
                    }
                }}
                // onClick={() => router.push(sport.path)}
            >
                <Typography
                    variant="caption"
                    fontSize={9}
                    lineHeight="11px"
                    sx={{
                        position: 'absolute',
                        top: 5,
                        right: 10
                    }}
                >
                    200
                </Typography>
                <Icon icon="ph:basketball-fill" width="30" height="30" color={isActive ? 'white' : sport.color} />
                <Typography fontSize={10} lineHeight="12px" color={isActive ? 'white' : 'translucent.03'}>
                    {sport.name}
                </Typography>
            </Button>
        </Box>
    );
};
export default SportBtn;
