// @mui
import Box from '@mui/material/Box';
import { useMediaQuery, useTheme } from '@mui/material';
// related components
// ---- desktop ----
import ListBar from './ListBar';
import EventView from './EventView';
import SlipBoard from './SlipBoard';

// ---- mobile ----
import MobileListBar from './mobileListBar';

const DesktopEvent = ({ isLive }: { isLive: boolean }) => {
    return (
        <>
            <Box sx={{ px: 1, gridArea: 'content', width: 1 }}>
                <Box sx={{ display: 'flex', height: 1, position: 'relative', pb: 1 }}>
                    <ListBar />
                    <EventView isLive={isLive} />
                </Box>
            </Box>
            <SlipBoard />
        </>
    );
};

const MobileEvent = ({ isLive }: { isLive: boolean }) => {
    return (
        <Box sx={{ px: 0.5, gridArea: 'unset', width: '100%' }}>
            <Box sx={{ display: 'flex', height: 1, position: 'relative', pb: 1 }}>
                <MobileListBar isLive={isLive} />
            </Box>
        </Box>
    );
};

const Event = ({ isLive }: { isLive: boolean }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    if (isMobile) {
        return <MobileEvent isLive={isLive} />;
    }

    return <DesktopEvent isLive={isLive} />;
};

export default Event;
