import { Link as ReactLink } from 'react-router-dom';
import { Box } from '@mui/material';

const Logo = () => {
    return (
        <Box
            component={ReactLink}
            to="/home"
            sx={{
                height: '100%'
            }}
        >
            <Box
                component="img"
                src="https://www.baladbet.com/logo.png?v=1722577124"
                sx={{ py: 0.4, maxWidth: '200px', display: 'flex', height: 1 }}
            />
        </Box>
    );
};
export default Logo;
