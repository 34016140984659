import { useState } from 'react';
// @mui
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Popover from '@mui/material/Popover';
import Collapse from '@mui/material/Collapse';
import Typography from '@mui/material/Typography';
import { useBoolean } from 'hooks/use-boolean';
// config
import { COLOR_LIST } from 'config';

const EventItem = () => {
    return (
        <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            sx={{
                px: 1,
                height: 34,
                cursor: 'pointer',
                borderRadius: 1,
                bgcolor: 'translucent.04',
                transition: 'background 0.24s'
            }}
        >
            <Stack sx={{ justifyContent: 'center', flex: 'auto', height: 1, overflow: 'hidden' }}>
                <Typography
                    sx={{
                        mr: 1,
                        lineHeight: 1,
                        color: '#fffc',
                        fontSize: 12,
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                        textTransform: 'capitalize'
                    }}
                >
                    Turkey
                </Typography>
            </Stack>
            <Typography component="span" sx={{ color: 'translucent.03', fontSize: 12 }}>
                1
            </Typography>
        </Stack>
    );
};

const CountryItem = () => {
    const isOpened = useBoolean(false);

    return (
        <Box
            sx={{
                px: 0.5,
                pb: isOpened.value ? 0.5 : 0,
                transition: 'all 0.24s',
                bgcolor: isOpened.value ? 'translucent.01' : 'translucent.05',
                boxShadow: (theme) => `inset 0 1px 0 0 ${theme.palette.translucent['01']}`
            }}
        >
            <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                sx={{
                    px: 0.5,
                    height: 34,
                    cursor: 'pointer',
                    borderRadius: 1,
                    transition: 'background 0.24s'
                }}
            >
                <Box component="i" className="bc-i-Soccer" sx={{ width: 18, height: 12, fontSize: 18, mr: 1 }} />
                <Stack sx={{ justifyContent: 'center', flex: 'auto', height: 1, overflow: 'hidden' }}>
                    <Typography
                        sx={{
                            mr: 1,
                            lineHeight: 1,
                            color: '#fffc',
                            fontSize: 12,
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis',
                            textTransform: 'capitalize'
                        }}
                    >
                        Turkey
                    </Typography>
                    <Typography
                        sx={{
                            mr: 1,
                            lineHeight: 1,
                            color: '#fffc',
                            fontSize: 12,
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis',
                            textTransform: 'capitalize'
                        }}
                    >
                        TBL
                    </Typography>
                </Stack>
                <Typography component="span" sx={{ color: 'translucent.03', fontSize: 12 }}>
                    1
                </Typography>
                <Box
                    component="i"
                    onClick={isOpened.onToggle}
                    className={`sp-s-l-h-arrow-icon-bc bc-i-small-arrow-${isOpened.value ? 'up' : 'down'}`}
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: 24,
                        height: 24,
                        ml: 1,
                        fontSize: 11,
                        borderRadius: 4,
                        lineHeight: 23,
                        color: 'translucent.06',
                        transition: 'color 0.24s, background 0.24s'
                    }}
                />
            </Stack>

            <Collapse in={isOpened.value}>
                <Stack
                    spacing={0.2}
                    sx={{
                        p: 0.2,
                        borderRadius: 1,
                        bgcolor: 'background.default',
                        opacity: 1,
                        maxHeight: '14000px',
                        pointerEvents: 'auto',
                        willChange: 'scroll-position',
                        overflowX: 'hidden',
                        overflowY: 'auto'
                    }}
                >
                    {new Array(3).fill(1).map((i) => (
                        <EventItem key={i} />
                    ))}
                </Stack>
            </Collapse>
        </Box>
    );
};

const SportItem = ({ index }: { index: number }) => {
    const isOpened = useBoolean();

    return (
        <Box sx={{ bgcolor: 'translucent.01', borderRadius: 1, transition: 'all 0.24s' }}>
            <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                sx={{
                    height: 34,
                    px: 1,
                    cursor: 'pointer',
                    borderRadius: 1,
                    bgcolor: 'background.default',
                    boxShadow: (theme) => `inset 0 0 0 18px ${theme.palette.translucent['01']}`,
                    transition: 'background  0.24s',
                    // -------------------------------
                    position: 'sticky',
                    top: 0,
                    zIndex: 1
                }}
            >
                <Box component="i" className="bc-i-Soccer" sx={{ color: COLOR_LIST[index], width: 20, mr: 1 }} />
                <Box
                    sx={{
                        display: 'flex',
                        flex: 'auto',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        height: 1
                    }}
                >
                    <Typography
                        sx={{
                            fontSize: 12,
                            mr: 1,
                            textTransform: 'capitalize',
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis'
                        }}
                    >
                        Fooball
                    </Typography>
                </Box>
                <Typography component="span" sx={{ color: 'translucent.03', fontSize: 12 }}>
                    18
                </Typography>
                <Box
                    onClick={isOpened.onToggle}
                    component="i"
                    className="sp-s-l-h-arrow-icon-bc bc-i-small-arrow-down"
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: 24,
                        height: 24,
                        ml: 1,
                        fontSize: 11,
                        borderRadius: 1,
                        lineHeight: 23,
                        transition: 'color 0.24s, background 0.24s',
                        color: isOpened.value ? 'common.white' : 'translucent.06',
                        ...(isOpened.value && {
                            bgcolor: COLOR_LIST[index],
                            transform: 'rotate(180deg)'
                        })
                    }}
                />
            </Stack>
            <Collapse in={isOpened.value}>
                {new Array(4).fill(1).map((_, index) => (
                    <CountryItem key={index} />
                ))}
            </Collapse>
        </Box>
    );
};

const UpcomingList = () => {
    const [marketAanchor, setMarketAnchor] = useState<HTMLElement | null>(null);

    const openMarketPopover = (event: React.MouseEvent<HTMLElement>) => {
        setMarketAnchor(event.currentTarget as HTMLElement);
    };

    const closeLangPopover = () => {
        setMarketAnchor(null);
    };

    return (
        <>
            <Stack direction="row" sx={{ p: 0.5, my: 0.5, height: 46, borderRadius: 1, bgcolor: 'translucent.05' }}>
                <Button
                    size="small"
                    onClick={openMarketPopover}
                    sx={{ bgcolor: 'translucent.04', py: 0, height: 28, color: '#ffffffe6' }}
                    startIcon={
                        <Box
                            component="i"
                            className="select-icon-bc bc-i-time"
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                fontSize: '14px !important',
                                width: 26,
                                height: 26,
                                color: '#ffffffe6',
                                textAlign: 'center'
                            }}
                        />
                    }
                    endIcon={
                        <Box
                            component="i"
                            className="form-control-icon-bc bc-i-small-arrow-down"
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                fontSize: '10px !important',
                                width: 20,
                                height: 20,
                                textAlign: 'center'
                            }}
                        />
                    }
                >
                    WINNER
                </Button>
            </Stack>

            <Box
                sx={{
                    mt: 0.2,
                    flex: 'auto',
                    position: 'relative',
                    height: 1,
                    willChange: 'scroll-position',
                    overflowX: 'hidden',
                    overflowY: 'scroll'
                }}
            >
                <Stack
                    sx={{ position: 'absolute', top: 0, left: 0, width: 1, minHeight: '100%', pr: 0.2 }}
                    spacing={0.2}
                >
                    {new Array(17).fill(1).map((_, i) => (
                        <SportItem key={i} index={i} />
                    ))}
                </Stack>
            </Box>

            <Popover
                id="market-popover"
                open={Boolean(marketAanchor)}
                anchorEl={marketAanchor}
                onClose={closeLangPopover}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center'
                }}
                sx={{
                    '.MuiPopover-paper': {
                        p: 0,
                        // minWidth: 288,
                        maxHeight: 227,
                        bgcolor: 'background.default',
                        boxShadow: '0 0 10px 3px #0000004d, inset 0 0 0 300px #ffffff0d'
                    }
                }}
            >
                <Stack sx={{ p: 0.5 }} spacing={0.2}>
                    {new Array(3).fill(1).map((_, i) => (
                        <Stack
                            key={i}
                            onClick={() => closeLangPopover()}
                            direction="row"
                            alignItems="center"
                            sx={{
                                px: 1,
                                height: 30,
                                borderRadius: 1,
                                cursor: 'pointer',
                                bgcolor: 'translucent.01',
                                '&:hover': {
                                    bgcolor: 'translucent.02'
                                }
                            }}
                        >
                            <Typography sx={{ fontSize: 12, userSelect: 'none' }}>Match Result</Typography>
                        </Stack>
                    ))}
                </Stack>
            </Popover>
        </>
    );
};

export default UpcomingList;
