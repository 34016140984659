// layouts
import SportLayout from 'layouts/SportLayout';
// ----------------------------------------------------------------------
// OVERVIEW
// const EventView = lazy(() => import('pages/sports/Event'));
// const LiveCalendar = lazy(() => import('pages/sports/LiveCalendar'));
// const Result = lazy(() => import('pages/sports/Result'));
import EventView from 'pages/sports/Event';
import SportMatches from 'pages/sports/Event/Matches';
import LiveCalendar from 'pages/sports/LiveCalendar';
import Result from 'pages/sports/Result';
import Odds from 'pages/sports/Event/Odds';
// ----------------------------------------------------------------------

export const mobileSportsRoutes = [
    {
        path: '/sports/live',
        element: <SportLayout />,
        children: [
            {
                path: 'event-view',
                element: <EventView isLive={true} />
            },
            {
                path: 'event-view/:sport',
                element: <EventView isLive={true} />
            },
            {
                path: 'event-view/:sport/:country/:leagueGroup/:league/:matchId',
                element: <Odds isLive />
            },
            {
                path: 'live-calendar',
                element: <LiveCalendar />
            },
            {
                path: 'results',
                element: <Result />
            }
        ]
    },
    {
        path: '/sports/pre-match',
        element: <SportLayout />,
        children: [
            {
                path: 'event-view',
                element: <EventView isLive={false} />
            },
            {
                path: 'event-view/:sport',
                element: <EventView isLive={false} />
            },
            {
                path: 'event-view/:sport/:country',
                element: <SportMatches />
            },
            {
                path: 'event-view/:sport/:country/:leagueGroup',
                element: <SportMatches />
            },
            {
                path: 'event-view/:sport/:country/:leagueGroup/:league',
                element: <SportMatches />
            },
            {
                path: 'event-view/:sport/:country/:leagueGroup/:league/:matchId',
                element: <Odds isLive={false} />
            },
            {
                path: 'live-calendar',
                element: <LiveCalendar />
            },
            {
                path: 'results',
                element: <Result />
            }
        ]
    }
];
