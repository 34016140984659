import { useState } from 'react';
// @mui
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import Checkbox from '@mui/material/Checkbox';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { useMediaQuery, useTheme } from '@mui/material';

const names = [
    'Oliver Hansen',
    'Van Henry',
    'April Tucker',
    'Ralph Hubbard',
    'Omar Alexander',
    'Carlos Abbott',
    'Miriam Wagner',
    'Bradley Wilkerson',
    'Virginia Andrews',
    'Kelly Snyder'
];

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
            marginLeft: -7,
            background: '#000c24',
            gap: 0.1
        }
    }
};
const timeItems = ['15.11 FRI', '16.11 FRI', '17.11 FRI', '18.11 FRI', '19.11 FRI', '20.11 FRI', '21.11 FRI'];
type timeItemProps = {
    name: string;
    isActive: boolean;
    changeTimeRange: (name: string) => void;
};
const TimeItem = (props: timeItemProps) => {
    const { name, isActive, changeTimeRange } = props;
    return (
        <Button
            onClick={() => changeTimeRange(name)}
            variant={isActive ? 'contained' : 'outlined'}
            sx={{
                position: isActive ? 'sticky' : 'initial',
                minWidth: 'max-content',
                px: 2,
                left: '0px',
                right: '0px'
            }}
            size="small"
        >
            {name}
        </Button>
    );
};

const FilterPart = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const [selectedTimeRange, setSelectedTimeRange] = useState<string>(timeItems[0]);
    const [personName, setPersonName] = useState<string[]>([]);

    const handleChange = (event: SelectChangeEvent<typeof personName>) => {
        const {
            target: { value }
        } = event;
        setPersonName(typeof value === 'string' ? value.split(',') : value);
    };

    return (
        <Box px={isMobile ? 1 : 0}>
            <Stack direction={isMobile ? 'column' : 'row'} alignItems="center" sx={{ mb: 1 }} gap={isMobile ? 1 : 0}>
                <Box sx={{ flex: isMobile ? '100%' : '30%', width: isMobile ? '100%' : 'auto', maxWidth: '400px' }}>
                    <FormControl sx={{ width: 1 }} variant="filled">
                        <InputLabel>Sport</InputLabel>
                        <Select
                            multiple
                            value={personName}
                            onChange={handleChange}
                            renderValue={(selected) => selected.join(', ')}
                            MenuProps={MenuProps}
                        >
                            {names.map((name) => (
                                <MenuItem
                                    sx={{
                                        bgcolor: 'translucent.05',
                                        marginBottom: '2px !important'
                                    }}
                                    key={name}
                                    value={name}
                                >
                                    <ListItemText primary={name} />
                                    <Checkbox checked={personName.includes(name)} />
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Box>

                <Stack
                    direction="row"
                    alignItems="center"
                    sx={{
                        width: isMobile ? '100%' : '70%',
                        ml: isMobile ? 0 : 1,
                        borderRadius: 1,
                        bgcolor: isMobile ? 'transparent' : 'translucent.05',
                        p: isMobile ? 0 : 1.2,

                        overflowX: 'auto',
                        overflowY: 'hidden',
                        '& ::-webkit-scrollbar': {
                            display: 'none'
                        },
                        '& ::-moz-scrollbar': {
                            display: 'none'
                        },
                        scrollbarWidth: 'none',
                        msOverflowStyle: 'none'
                    }}
                >
                    <Box
                        sx={{
                            gap: 0.5,
                            width: 1,
                            display: 'flex',
                            // justifyContent: 'flex-end',
                            // gridTemplateColumns: 'repeat(auto-fill, minmax(94px, 1fr))',
                            overflow: 'auto',
                            position: 'relative'
                        }}
                    >
                        {timeItems.map((item, i) => (
                            <TimeItem
                                changeTimeRange={setSelectedTimeRange}
                                isActive={item === selectedTimeRange}
                                name={item}
                                key={i}
                            />
                        ))}
                    </Box>
                </Stack>
            </Stack>
        </Box>
    );
};

export default FilterPart;
