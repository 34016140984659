import { useEffect, useState } from 'react';
// @mui
import { Stack, Button, IconButton, Popover, Typography, Box } from '@mui/material';
// components
import SportBtn from 'components/Sports/SportBtn';
// other components
import { Icon } from '@iconify/react';
// config
import { sportNavigations } from 'config';
// close components
import LiveList from './LiveList';
import UpcomingList from './UpcomingList';
// hooks
import { useParams, useRouter } from 'routes/hook';
// types
import { ISportParams } from 'types/sports';
import { paths } from 'routes/paths';

type propsType = {
    isLive: boolean;
};

const matchesNavigation = [
    {
        title: 'Upcoming Matchs',
        icon: <Icon icon="material-symbols:calendar-clock" width="14" height="14" color="#0097b7" />,
        color: '#0097b7'
    },
    {
        title: 'Popular Matchs',
        icon: <Icon icon="solar:cup-star-bold" width="14" height="14" color="#583de3" />,
        color: '#583de3'
    },
    {
        title: 'Popular competitions',
        icon: <Icon icon="solar:cup-star-bold" width="14" height="14" color="#2275e9" />,
        color: '#2275e9'
    }
];

const MobileListBar = (props: propsType) => {
    const { isLive } = props;
    const params: ISportParams = useParams();
    const router = useRouter();

    const [marketAanchor, setMarketAnchor] = useState<HTMLElement | null>(null);

    const openMarketPopover = (event: React.MouseEvent<HTMLElement>) => {
        setMarketAnchor(event.currentTarget as HTMLElement);
    };

    const closeLangPopover = () => {
        setMarketAnchor(null);
    };

    useEffect(() => {
        if (!params.sport) {
            if (isLive) {
                router.push(`${paths.sports.live.event_view}/soccer`);
            } else {
                router.push(`${paths.sports.pre.event_view}/soccer`);
            }
        }
    }, [params, router, isLive]);

    return (
        <Stack sx={{ width: '100%', transition: 'width 0.24s' }}>
            {!isLive && (
                <Box
                    width={1}
                    sx={{
                        overflowX: 'auto',
                        overflowY: 'hidden',
                        minHeight: '36px',
                        '& ::-webkit-scrollbar': {
                            height: '0px',
                            display: 'none'
                        },
                        '& ::-moz-scrollbar': {
                            display: 'none'
                        },
                        scrollbarWidth: 'none',
                        msOverflowStyle: 'none'
                    }}
                >
                    <Stack
                        direction="row"
                        gap={1}
                        sx={{
                            width: 'fit-content',
                            borderRadius: 1,
                            overflowX: 'scroll'
                        }}
                    >
                        {matchesNavigation.map((item, key) => (
                            <Button
                                variant="soft"
                                key={key}
                                startIcon={item.icon}
                                sx={{
                                    py: 0.5,
                                    bgcolor: 'translucent.01',
                                    minWidth: 'fit-content',
                                    px: 2
                                }}
                            >
                                {item.title}
                            </Button>
                        ))}
                    </Stack>
                </Box>
            )}
            {isLive ? (
                <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    sx={{
                        p: 0.5,
                        mt: 0.2,
                        height: 40,
                        borderRadius: 1
                    }}
                >
                    <Stack direction="row">
                        <Button variant="outlined" sx={{ px: 1.5, py: 0, minWidth: 0, height: 28 }}>
                            <Icon icon="gridicons:video-camera" style={{ width: 18, height: 18 }} />
                        </Button>
                        <Button variant="outlined" sx={{ px: 1.5, py: 0, minWidth: 0, height: 28, ml: 1 }}>
                            <Icon icon="mdi:world-wide-web" style={{ width: 18, height: 18 }} />
                        </Button>
                    </Stack>
                    <IconButton
                        sx={{
                            borderRadius: 1,
                            width: 36,
                            height: 36,
                            border: 'solid 1px #ffffff1a'
                        }}
                    >
                        <Icon
                            icon="heroicons-solid:search"
                            style={{
                                display: 'inline-block',
                                verticalAlign: 'top',
                                position: 'absolute',
                                top: 0,
                                right: 0,
                                bottom: 0,
                                left: 0,
                                margin: 'auto',
                                width: 22,
                                height: 22,
                                color: '#ffffff80'
                            }}
                        />
                    </IconButton>
                </Stack>
            ) : (
                <Stack
                    sx={{ borderRadius: 1, py: 0.7 }}
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <Stack direction="row" gap={1}>
                        <Button
                            size="small"
                            onClick={openMarketPopover}
                            sx={{ bgcolor: 'translucent.04', py: 0, px: 1 }}
                            startIcon={
                                <Box
                                    component="i"
                                    className="select-icon-bc bc-i-time"
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        fontSize: '14px !important',
                                        width: 26,
                                        height: 26,
                                        color: '#ffffffe6',
                                        textAlign: 'center'
                                    }}
                                />
                            }
                            endIcon={
                                <Box
                                    component="i"
                                    className="form-control-icon-bc bc-i-small-arrow-down"
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        fontSize: '10px !important',
                                        width: 20,
                                        height: 20,
                                        textAlign: 'center'
                                    }}
                                />
                            }
                        >
                            All
                        </Button>
                        <Button variant="outlined" size="small">
                            <Box
                                component="i"
                                className="filter-icon-bc bc-i-bet-builder"
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    fontSize: '14px !important',
                                    width: 26,
                                    height: 26,
                                    color: '#ffffffe6',
                                    textAlign: 'center'
                                }}
                            />
                        </Button>
                    </Stack>

                    <IconButton
                        sx={{
                            borderRadius: 1,
                            width: 36,
                            height: 36,
                            border: 'solid 1px #ffffff1a'
                        }}
                    >
                        <Icon
                            icon="heroicons-solid:search"
                            style={{
                                display: 'inline-block',
                                verticalAlign: 'top',
                                position: 'absolute',
                                top: 0,
                                right: 0,
                                bottom: 0,
                                left: 0,
                                margin: 'auto',
                                width: 22,
                                height: 22,
                                color: '#ffffff80'
                            }}
                        />
                    </IconButton>
                    <Popover
                        id="market-popover"
                        open={Boolean(marketAanchor)}
                        anchorEl={marketAanchor}
                        onClose={closeLangPopover}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center'
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'center'
                        }}
                        sx={{
                            '.MuiPopover-paper': {
                                p: 0,
                                minWidth: 100,
                                maxHeight: 227,
                                bgcolor: 'background.default',
                                boxShadow: '0 0 10px 3px #0000004d, inset 0 0 0 300px #ffffff0d'
                            }
                        }}
                    >
                        <Stack sx={{ p: 0.5 }} spacing={0.2}>
                            {new Array(3).fill(1).map((_, i) => (
                                <Stack
                                    key={i}
                                    onClick={() => closeLangPopover()}
                                    direction="row"
                                    alignItems="center"
                                    sx={{
                                        px: 1,
                                        height: 30,
                                        borderRadius: 1,
                                        cursor: 'pointer',
                                        bgcolor: 'translucent.01',
                                        '&:hover': {
                                            bgcolor: 'translucent.02'
                                        }
                                    }}
                                >
                                    <Typography sx={{ fontSize: 12, userSelect: 'none' }}>All</Typography>
                                </Stack>
                            ))}
                        </Stack>
                    </Popover>
                </Stack>
            )}
            <Box
                width={1}
                sx={{
                    overflowX: 'auto',
                    overflowY: 'hidden',
                    '& ::-webkit-scrollbar': {
                        display: 'none'
                    },
                    '& ::-moz-scrollbar': {
                        display: 'none'
                    },
                    scrollbarWidth: 'none',
                    msOverflowStyle: 'none'
                }}
            >
                <Stack
                    direction="row"
                    sx={{ mt: 0.2, gap: 0.5, mb: 0.5, width: '100%', overflow: 'auto', position: 'relative' }}
                >
                    {sportNavigations.map((item, i) => (
                        <SportBtn isLive sport={item} key={i} />
                    ))}
                </Stack>
            </Box>
            {isLive ? <LiveList /> : <UpcomingList />}
        </Stack>
    );
};

export default MobileListBar;
