import axios from 'axios';
// config
import { HOST_API_KEY } from 'config';

// ----------------------------------------------------------------------

const axiosInstance = axios.create({ baseURL: HOST_API_KEY });

axiosInstance.interceptors.response.use(
    (response) => response,
    (error) => Promise.reject((error.response && error.response.data) || 'Something went wrong')
);

export default axiosInstance;

export const ASSETS = (name: string) => (name ? `${HOST_API_KEY}/${name}` : '');

export const endpoints = {
    auth: {
        forgotPassword: '/api/auth/forgot_password',
        existResetToken: '/api/auth/exist_reset_token',
        resetPassword: '/api/auth/reset_password'
    },
    user: {
        getUser: '/api/user/forgot_password'
    },
    sports: {
        getMatchs: '/api/sports/get'
    },
    casino: {
        getCasinos: '/api/casino/get'
    }
};
