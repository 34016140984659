import { IconButton, styled } from '@mui/material';
import { PickersDay } from '@mui/x-date-pickers';

export const StyledButton = styled(IconButton)(({ theme }) => ({
    borderRadius: theme.shape.borderRadius
}));
export const StyledDay = styled(PickersDay)(({ theme }) => ({
    borderRadius: theme.shape.borderRadius,
    color: theme.palette.secondary.light,
    ...theme.applyStyles('light', {
        color: theme.palette.secondary.dark
    })
}));
